/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

const SenhaJaAssociada = ({ dispatch, classes }) => {
  const navigate = useNavigate();

  //   useEffect(() => fetch(), []);

  const loginRedirect = (e) => {
    e.preventDefault();
    navigate('/');
    // Limpar aqui a "senha" no Redux
    dispatch({ type: 'CLEAR_DADOS' });
  };

  return (
    <>
      <div className='login-submit-section'>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            marginBottom: '10px',
          }}
        >
          <p style={{ color: 'black' }}>Este link já foi utilizado.</p>
        </div>

        <Button
          variant='contained'
          color='primary'
          // className="btn"
          // className={classes.btnDefinirSenha}
          type='submit'
          onClick={loginRedirect}
          className={classes.btnGuardar}
        >
          Ir para Login
        </Button>
      </div>
    </>
  );
};

export default SenhaJaAssociada;
