import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#464255',
      contrastText: '#fff',
    },
    secondary: {
      main: '#000',
      contrastText: '#fff',
    },
    text: {
      primary: '#333333',
      secondary: '#929CA6',
      tertiary: '#D6DFE8',
    },

    body: {
      main: '#464255',
    },
  },
  typography: {
    fontFamily: ['poppins', 'sans-serif'].join(','),
    //Muda as cores dentro dos campos dos components
    allVariants: {
      color: '#464255',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
        },
      },

      variants: [
        {
          props: { variant: 'contained' },
          style: {
            '&:hover': {
              backgroundColor: '#5D646D',
            },
            boxShadow: '0px 2px rgba(0, 0, 0, 0.1)',
          },
          root: {
            borderRadius: '20px !important',
            '&:hover': {
              backgroundColor: '#5D646D',
              borderRadius: '20px !important',
            },
          },
        },
        {
          props: { variant: 'contained', color: 'secondary' },
          style: {
            '&:hover': {
              backgroundColor: '#98A1AB',
            },
            boxShadow: '0px 2px rgba(0, 0, 0, 0.1)',
          },
        },
      ],
    },
    // TABELAS
    MUIDataTableBodyCell: {
      styleOverrides: {
        root: {
          // maxHeight: '0px',
          border: 'none',
          color: '#000',
          fontSize: '16px',
        },
      },
    },
    MUIDataTableBodyRow: {
      styleOverrides: {
        root: {
          // maxHeight: '0px',
          border: '1px solid #707070',
          borderRadius: '20px',
          color: '#929CA6',
          margin: '20px',
          padding: '20px',
        },
      },
    },
    MUIDataTableBody: {
      styleOverrides: {
        emptyTitle: {
          // lineHeight: 0,
        },
      },
    },

    MUIDataTable: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
        },

        paper: {
          boxShadow: 'none',
        },
        caption: {
          display: 'none',
        },
      },
    },
    MUIDataTablePagination: {
      styleOverrides: {
        tableCellContainer: {
          padding: 0,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          border: 'none',
        },
        head: {
          background: '#F1F1F1 !important',
        },
        footer: {
          background: '#FFF !important',
          '&:hover': {
            // transition: '0.2s !important',
            background: '#FFF !important',
          },
        },
      },
    },
    MUIDataTableHeadCell: {
      styleOverrides: {
        data: {
          color: '#333',
          fontWeight: 'bold',
          fontSize: '16px',
        },
      },
    },

    MuiTableBody: {
      styleOverrides: {
        root: {
          '& td:first-of-type': {
            // background: 'red',
            borderTopLeftRadius: '10px',
            borderBottomLeftRadius: '10px',
            borderLeft: '1px solid grey',
          },
          '& td:last-of-type': {
            // background: 'red',
            borderTopRightRadius: '10px',
            borderBottomRightRadius: '10px',
            borderRight: '1px solid grey',
          },
          '& td': {
            borderTop: '1px solid grey',
            borderBottom: '1px solid grey',
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        body1: {
          // background: 'lightgreen',
          fontSize: '16px',
          // lineHeight: 0,
        },
      },
    },

    MuiTableRow: {
      styleOverrides: {
        root: {
          transition: '0.2s !important',
          '&:hover': {
            transition: '0.2s !important',
            background: '#F3F5F7 !important',
          },
          borderRadius: '10px',
        },
        head: {
          '& th:first-of-type': {
            // background: 'red',
            borderRadius: '10px 0px 0px 10px',
            borderLeft: '1px solid grey',
          },
          '& th:last-of-type': {
            // background: 'red',
            borderRadius: '0px 10px 10px 0px',
            borderRight: '1px solid grey',
          },
          '& th': {
            borderTop: '1px solid grey',
            borderBottom: '1px solid grey',
          },
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          borderCollapse: 'separate',
          borderSpacing: '0 1em',
        },
      },
    },
    // MUIDataTableHeadRow: {
    //   styleOverrides: {
    //     root: {
    //       background: 'salmon',
    //     },
    //   },
    // },
    MUIDataTableToolbar: {
      styleOverrides: {
        root: {
          paddingLeft: 0,
          paddingRight: 0,
        },
        left: {
          display: 'flex',
        },
      },
    },

    MuiChip: {
      styleOverrides: {
        root: {
          height: '22px',
          background: '#929CA6',
          color: 'white',
          // margin: 0,
        },
        label: {
          paddingLeft: '10px',
          paddingRight: '8px',
        },
        deleteIcon: {
          color: '#DEE1E4',
          height: '18px',
          '&:hover': {
            color: '#D7D7D8',
          },
        },
      },
    },

    MuiList: {
      styleOverrides: {
        root: {
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
    },

    //MuiInput

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          // transition: 'linear 2s',
          borderRadius: '10px',
          backgroundColor: '#F3F2F7',

          // '&:hover': {
          //   backgroundColor: '#ff8',
          // },
          '&$focused': {
            backgroundColor: '#dfb',
          },
        },
        notchedOutline: {
          borderColor: '#F3F2F7 !important',
        },
        underline: {
          '&:before': {
            borderBottomColor: 'red',
          },
          '&:hover:not(.Mui-focused):before': {
            borderBottomColor: 'green',
          },
          '&:after': {
            // focused
            borderBottomColor: 'purple',
          },
        },
      },
    },
  },

  MuiInputBase: {
    styleOverrides: {
      root: {
        background: 'green',
        backgroundColor: 'red',
        '&:hover': {
          backgroundColor: '#ff8',
        },
        '&$focused': {
          backgroundColor: '#dfb',
        },
      },
      underline: {
        '&:before': {
          borderBottomColor: 'red',
        },
        '&:hover:not(.Mui-focused):before': {
          borderBottomColor: 'green',
        },
        '&:after': {
          // focused
          borderBottomColor: 'purple',
        },
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      filled: {
        color: 'purple',
        '&$focused': {
          color: 'green',
        },
        '.MuiFormControl-root:hover &:not($focused)': {
          color: 'red',
        },
      },
    },
  },
});

export default theme;
