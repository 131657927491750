const ShowNotification = (message) => async (dispatch) => {
  // console.log(message);
  switch (message.type) {
    case 'success':
      dispatch({ type: 'SNACKBAR_SUCCESS', payload: message });
      break;
    case 'warning':
      dispatch({ type: 'SNACKBAR_WARNING', payload: message });
      break;
    case 'error':
      dispatch({ type: 'SNACKBAR_ERROR', payload: message });
      break;
    case 'info':
      dispatch({ type: 'SNACKBAR_INFO', payload: message });
      break;
    default:
      return message.type;
  }
};

export default ShowNotification;
