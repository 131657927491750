/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react';
import './login.css';
//PACKAGES
import { useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { makeStyles } from '@mui/styles';

import {
  Button,
  TextField,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  Typography,
} from '@mui/material';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

//IMAGES

//COMPONENTS
import SignIn from '../../../utils/redux/actions/authAction';
import ShowNotification from '../../../utils/redux/actions/global/notifications/NotificationAction';
import FooterLinks from '../../../Global/Components/Footer';

// const CssTextField = styled(TextField)({
//   '& label.Mui-focused': {
//     color: '#464255 !important',
//   },
//   '& .MuiInput-underline:after': {
//     borderBottomColor: '#464255 !important',
//   },
//   '& .MuiOutlinedInput-root': {
//     borderRadius: '10px',
//     transition: '0.2s',
//     background: 'white',
//     '& input': {
//       backgroundColor: 'white',
//       transition: '0.2s',
//       borderRadius: '5px',
//     },
//     '& fieldset': {
//       color: '#474a52 !important',
//       transition: '0.2s',
//     },
//     '&:hover fieldset': {
//       borderColor: '#464255 !important',
//     },
//     '&.Mui-focused fieldset': {
//       borderColor: '#464255 !important',
//     },
//   },
// });
const CssTextField = styled(TextField)({
  borderRadius: '10px',
  '& label.Mui-focused': {
    // color: '#464255 !important',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#464255 !important',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '10px',
    transition: '0.2s',
    // background: '#F3F2F7',
    '& input': {
      transition: '0.2s',
      borderRadius: '10px',
      background: '#F3F2F7',
    },
    '& fieldset': {
      // color: '#474a52 !important',
      transition: '0.2s',
    },
    '&:hover fieldset': {
      borderColor: '#F3F2F7 !important',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#F3F2F7 !important',
    },
  },
});

const useStyles = makeStyles({
  btnGuardar: {
    color: 'white',
    width: '120px',
    height: '40px',
    // borderRadius: '15px',
    backgroundColor: '#A28DF4',
    border: `1px solid #A28DF4`,
    display: 'flex',
    alignItems: 'center',
    transition: '0.2s',
    '&:hover': {
      backgroundColor: '#856af0',
      border: '1px solid #856af0',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#bc1041',
      },
      '&:disabled': {
        backgroundColor: '#bc1041',
      },
    },
  },
});

const Login = ({ validateOnChange = true }) => {
  const classes = useStyles();
  const [errorsUser, setErrorsUser] = useState({});
  const [user, setUser] = useState({
    email: '',
    password: '',
  });
  const [values, setValues] = useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  //--------------------------------- validation ------------------------------/
  const validationUser = (fieldValues = user) => {
    const temp = { ...errorsUser };

    if ('email' in fieldValues) {
      // prettier-ignore
      temp.email = (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(fieldValues.email) ? '' : 'Coloque um email válido';
    }

    if ('password' in fieldValues)
      temp.password = fieldValues.password ? '' : 'Campo Obrigatório';

    setErrorsUser({
      ...temp,
    });
    if (fieldValues == user) {
      return Object.values(temp).every((x) => x == '');
    }
    return false;
  };
  //--------------------------------------------------------------------------/

  const handleChangePassword = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    setUser({ ...user, [prop]: event.target.value });

    if (validateOnChange)
      validationUser({ [event.target.name]: event.target.value });
  };
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleForm = (e) => {
    e.preventDefault();

    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateOnChange) validationUser({ [e.target.name]: e.target.value });

    const ydata = [
      {
        user: {
          email: user.email,
          password: user.password,
        },
      },
    ];

    const message = {
      isOpen: true,
      message: 'Utilizador logado com sucesso!!',
      type: 'success',
    };
    const errorMessage = {
      isOpen: true,
      message: 'Ocorreu um erro!!',
      type: 'error',
    };

    if (validationUser()) {
      dispatch(
        SignIn(ydata, navigate, ShowNotification, message, errorMessage)
      );
    }
  };
  const storage = localStorage;

  useEffect(() => {
    if (storage && storage.token) {
      navigate('/utilizadores');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {storage && storage.token ? (
        navigate('/utilizadores')
      ) : (
        <>
          <div className='home'>
            <div className='home-left'>
              <div className='left-content'>
                <div className='left-content-inside'></div>
              </div>
            </div>
            <div className='home-right'>
              {' '}
              <div className='login-form'>
                <div className='titulo'>
                  {/* <Typography color=''>Bem Vindo!</Typography> */}
                  <Typography variant='h5' style={{ marginTop: 20 }}>
                    <strong>Gestão de Cartões Pessoais</strong>
                  </Typography>
                </div>
                <form
                  className='loginForm'
                  noValidate
                  autoComplete='off'
                  onSubmit={handleSubmit}
                >
                  <CssTextField
                    id='email'
                    label='Email'
                    variant='outlined'
                    className='emailInput'
                    value={user.email}
                    name='email'
                    onChange={handleForm}
                    size='small'
                    {...(errorsUser.email && {
                      error: true,
                      helperText: errorsUser.email,
                    })}
                  />
                  <FormControl
                    onSubmit={handleSubmit}
                    variant='outlined'
                    {...(errorsUser.password && {
                      error: true,
                      helperText: errorsUser.password,
                    })}
                  >
                    <CssTextField
                      label='Password'
                      id='outlined-start-adornment'
                      value={values.password}
                      type={values.showPassword ? 'text' : 'password'}
                      name='password'
                      onChange={handleChangePassword('password')}
                      size='small'
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <IconButton
                              aria-label='toggle password visibility'
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge='end'
                            >
                              {values.showPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      variant='outlined'
                      {...(errorsUser.password && {
                        error: true,
                        // helperText: errorsUser.password,
                      })}
                    />
                    {errorsUser.password && (
                      <FormHelperText
                        style={{ color: '#d32f2f', borderColor: '#d32f2f' }}
                      >
                        {errorsUser.password}
                      </FormHelperText>
                    )}
                  </FormControl>
                  <div className='login-submit-section'>
                    <Button
                      variant='contained'
                      type='submit'
                      // onClick={handleSubmit}
                      // style={{ width: '100px' }}
                      className={classes.btnGuardar}
                    >
                      Entrar
                    </Button>

                    <Link to='/recuperarSenha'>
                      <p className='login-bottom-text'>Recuperar Password</p>
                    </Link>
                  </div>
                </form>
              </div>
              <div className='bottom'></div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Login;
