import { useDispatch } from 'react-redux';
import { Modal, Backdrop, Fade, Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';

//Action
// import addIntervencao from '../../../../../utils/redux/actions/intervencoes/AddIntervencaoAction';
import RemoverUtilizador from '../../../../utils/redux/actions/utilizadores/removerUtilizadorAction';
import ShowNotification from '../../../../utils/redux/actions/global/notifications/NotificationAction';
import FeatherIcon from 'feather-icons-react';

//Icons

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: 2,
  boxShadow: 3,
  px: 4,
  py: 4,
};

const useStyles = makeStyles((theme) => ({
  btnConfirm: {
    color: 'white',
    width: '120px',
    height: '40px',
    // borderRadius: '15px',
    backgroundColor: '#A28DF4',
    border: `1px solid #A28DF4`,
    display: 'flex',
    alignItems: 'center',
    transition: '0.2s',
    '&:hover': {
      backgroundColor: '#856af0',
      border: '1px solid #856af0',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#bc1041',
      },
      '&:disabled': {
        backgroundColor: '#bc1041',
      },
    },
  },
  // btnConfirm: {
  //   color: 'white',
  //   width: '120px',
  //   height: '40px',
  //   // borderRadius: '15px',
  //   backgroundColor: '#E21450',
  //   border: `1px solid #E21450`,
  //   display: 'flex',
  //   alignItems: 'center',
  //   transition: '0.2s',
  //   '&:hover': {
  //     backgroundColor: '#bc1041',
  //     border: '1px solid #bc1041',
  //     // Reset on touch devices, it doesn't add specificity
  //     '@media (hover: none)': {
  //       backgroundColor: '#bc1041',
  //     },
  //     '&:disabled': {
  //       backgroundColor: '#bc1041',
  //     },
  //   },
  // },
  btnCancel: {
    color: '#A28DF4',
    width: '120px',
    height: '40px',
    // borderRadius: '15px',
    backgroundColor: 'white',
    border: `1px solid white`,
    display: 'flex',
    alignItems: 'center',
    boxShadow: 'unset',
    transition: '0.2s',
    '&:hover': {
      backgroundColor: '#F0EDFD',
      border: '1px solid #F0EDFD',
      boxShadow: 'unset',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#bc1041',
      },
      '&:disabled': {
        backgroundColor: '#bc1041',
      },
    },
  },
  modalBtns: {
    paddingTop: 30,
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'flex-end',
  },
}));

const ModalAdd = ({
  openDelete,
  handleClose,
  utilizadorId,
  fetchUtilizadores,
  utilizadorNome,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleRemover = (e) => {
    e.preventDefault();

    const message = {
      isOpen: true,
      message: 'Utilizador removido com sucesso!!',
      type: 'success',
    };
    const errorMessage = {
      isOpen: true,
      message: 'Ocorreu um erro!!',
      type: 'error',
    };

    dispatch(
      RemoverUtilizador(
        utilizadorId,
        ShowNotification,
        message,
        errorMessage,
        handleClose,
        e
      )
    )
      .then(() => handleClose('delete', e))
      .then(() => fetchUtilizadores());
  };

  return (
    <>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={openDelete}
        onClose={(e) => handleClose('delete', e)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          backgroundcolor: 'rgb(0,0,0,0.1)',
        }}
      >
        <>
          <Fade in={openDelete}>
            <Box sx={style}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'start',
                }}
              >
                <Typography fontWeight='bold'>Remover Utilizador</Typography>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: '20px',
                    // justifyContent: 'space-between',
                    // width: '100%',
                    flexWrap: 'wrap',
                  }}
                >
                  <Typography>
                    Tem a certeza que quer eliminar o utilizador{' '}
                    <strong>{utilizadorNome}</strong> da lista?
                  </Typography>
                  <Typography
                    style={{
                      fontSize: '12px',
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: '10px',
                      color: '#FF5757',
                    }}
                  >
                    <FeatherIcon
                      icon='alert-circle'
                      size={18}
                      className={classes.deleteIcon}
                      style={{ strokeWidth: '2', marginRight: '5px' }}
                    />
                    Esta ação irá eliminar este utilizador permanentemente.
                  </Typography>
                </div>
              </div>
              <div className={classes.modalBtns}>
                <Button
                  variant='contained'
                  onClick={handleRemover}
                  sx={{ mx: 2 }}
                  className={classes.btnConfirm}
                >
                  Confirmar
                </Button>
                <Button
                  variant='contained'
                  color='secondary'
                  onClick={(e) => handleClose('delete', e)}
                  className={classes.btnCancel}
                >
                  Cancelar
                </Button>
              </div>
            </Box>
          </Fade>
        </>
      </Modal>
    </>
  );
};

export default ModalAdd;
