import Instance from '../../../Axios';

// New Actions for the app

const GetUtilizador = (id) => async (dispatch) => {
  console.log('id', id);
  await Instance()
    .get(`/gestao/utilizadores/${id}`)
    .then(({ data }) => {
      // console.log(data);
      dispatch({ type: 'GET_UTILIZADOR', payload: data.yData[0] });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetUtilizador;
