export const utilizadoresInitialState = {
  utilizadores: [],
  utilizador: {},
  novoUtilizador: {},
  utilizadorEditado: {},
  utilizadorRemovido: {},

  perfis: [],
  empresasDropdown: [],
  empresasDropdownBase: [],
};
