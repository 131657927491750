/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@mui/styles';
import { IconButton } from '@mui/material';
import MUIDataTable from 'mui-datatables';

//ICONS
import FeatherIcon from 'feather-icons-react';

//Actions
import GetEmpresas from '../../../utils/redux/actions/empresas/GetEmpresasAction';
import GetEmpresa from '../../../utils/redux/actions/empresas/GetEmpresaAction';

//Components
import CustomToolbar from './components/customToolbar';
import ModalDelete from './components/modalDelete';
import SideDrawer from './components/drawer';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      // margin: theme.spacing(1),
      width: '25ch',
    },
  },
  options: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'end',
  },

  paperFilters: {
    background: 'transparent',
    padding: '20px',
    boxShadow: 'none',
  },
}));

const Empresas = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [responsive] = useState('standard');
  const [tableBodyHeight] = useState('100%');
  const [tableBodyMaxHeight] = useState('');
  const [openDelete, setOpenDelete] = useState(false);

  const [empresaId, setEmpresaId] = useState();
  const [empresaNome, setEmpresaNome] = useState();
  const [empresa, setEmpresa] = useState({
    nome: '',
    url: '',
    morada: '',
    corFundo: '',
    corBotoes: '',
    corLetras: '',
    fundo1: '',
    fundo2: '',
    icon1: '',
    icon2: '',
    logotipo: '',
  });

  const resetUtilizador = () => {
    setEmpresa({
      nome: '',
      url: '',
      morada: '',
      corFundo: '',
      corBotoes: '',
      corLetras: '',
      fundo1: '',
      fundo2: '',
      icon1: '',
      icon2: '',
      logotipo: '',
    });
  };

  const [state, setState] = useState({
    right: false,
  });
  const [drawerType, setDrawerType] = useState(null);

  const fetchEmpresas = () => {
    dispatch(GetEmpresas());
  };

  const fetchEmpresa = (id) => {
    dispatch(GetEmpresa(id));
  };

  useEffect(() => fetchEmpresas(), []);

  const empresas = useSelector((state) => state.empresas.empresas);
  const empresaInfo = useSelector((state) => state.empresas.empresa);

  const handleOpen = (btn, e, id, tableMeta) => {
    e.preventDefault();

    if (btn === 'delete') {
      setOpenDelete(true);
      setEmpresaId(id);
      setEmpresaNome(tableMeta.rowData[1]);
    }
  };

  const handleClose = (btn, e) => {
    e.preventDefault();
    if (btn === 'delete') {
      setOpenDelete(false);
    }
  };

  const toggleDrawer = (anchor, open, empresaId, ds) => {
    console.log(anchor, open, empresaId, ds);
    if (open == false) {
      // setUtilizadorId(userId);
      setState({ ...state, [anchor]: open });
      dispatch({
        type: 'GET_EMPRESA',
        payload: {
          nome: '',
          url: '',
          morada: '',
          corFundo: '',
          corBotoes: '',
          corLetras: '',
          fundo1: '',
          fundo2: '',
          icon1: '',
          icon2: '',
          logotipo: '',
        },
      });
    }
    if (open == true) {
      resetUtilizador();

      setDrawerType(ds);
      setEmpresaId(empresaId);
      setState({ ...state, [anchor]: open });
      fetchEmpresa(empresaId);
    }
  };

  const columns = [
    {
      name: 'id',
      label: 'id',
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: 'nome',
      label: 'Nome',
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: '',
      label: '',
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellProps: () => ({
          align: 'center',
        }),
        customBodyRender: (value, tableMeta) => {
          const empresaId = tableMeta.rowData[0];

          return (
            <div className={classes.options}>
              <IconButton
                style={{
                  padding: 6,
                }}
                // onClick={(e) => handleOpen('edit', e, id, tableMeta)}
                onClick={() => toggleDrawer('right', true, empresaId, 'edit')}
              >
                <FeatherIcon
                  icon='edit'
                  size={20}
                  style={{ strokeWidth: '1.5' }}
                />
              </IconButton>

              <IconButton
                style={{
                  padding: 6,
                }}
                onClick={(e) => handleOpen('delete', e, empresaId, tableMeta)}
              >
                <FeatherIcon
                  icon='trash-2'
                  size={20}
                  style={{ strokeWidth: '1.5' }}
                />
              </IconButton>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filter: false,
    filterType: 'dropdown',
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    selectableRows: 'none',
    viewColumns: false,
    elevation: 5,
    pagination: true,
    print: false,
    download: false,
    rowsPerPage: 50,
    rowsPerPageOptions: [50, 100, 150, 200],
    // setRowProps: (row, dataIndex, rowIndex) => {
    //   return {
    //     style:
    //       rowIndex % 2
    //         ? { backgroundColor: 'transparent' }
    //         : { backgroundColor: '#F3F5F8' },
    //   };
    // },
    textLabels: {
      body: {
        noMatch: 'Sem Registos encontrados',
        // toolTip: 'Dia',
        columnHeaderTooltip: (column) => `${column.label}`,
      },
      toolbar: {
        search: 'Pesquisar',
      },
      pagination: {
        next: 'Proxima Página',
        previous: 'Página Anterior',
        rowsPerPage: 'Mostrar:',
        displayRows: 'de',
      },
    },

    customToolbar: () => {
      return (
        <>
          <CustomToolbar
            handleOpen={handleOpen}
            onClose={handleClose}
            toggleDrawer={toggleDrawer}
          />
        </>
      );
    },
  };

  return (
    <>
      <ModalDelete
        openDelete={openDelete}
        handleClose={handleClose}
        empresaId={empresaId}
        fetchEmpresas={fetchEmpresas}
        empresaNome={empresaNome}
      />
      <SideDrawer
        state={state}
        toggleDrawer={toggleDrawer}
        empresas={empresas}
        fetchEmpresas={fetchEmpresas}
        drawerType={drawerType}
        empresa={empresa}
        setEmpresa={setEmpresa}
        empresaId={empresaId}
        empresaInfo={empresaInfo}
      />
      <div
        style={{
          display: 'flex',
          height: '94vh',
          flexDirection: 'column',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        {/* <div style={{ padding: '40px', width: 'calc(100vw - 55px)' }}> */}
        <div style={{ padding: '40px' }}>
          <MUIDataTable
            title={
              <span
                style={{
                  fontSize: '20px',
                  fontWeight: 'bold',
                }}
                className='text-underline'
              >
                Empresas
              </span>
            }
            data={empresas || []}
            columns={columns}
            options={options}
          />
        </div>
        <div style={{ padding: '20px 40px' }}>{/* <FooterLinks /> */}</div>
      </div>
    </>
  );
};

export default Empresas;
