import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
// import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Drawer } from '@mui/material';
//Components
import FormEdit from './forms/formEdit';
import FormAdd from './forms/formAdd';

const useStyles = makeStyles({
  list: {
    width: '50vw',
    borderRadius: '10px 0px 0px 10px !important',
  },
  fullList: {
    width: 'auto',
  },
  paper: {
    background: '#FAFAFA',
    borderRadius: ' 12px 0px 0px 12px',
  },
  // btnGuardar: {
  //   color: 'white',
  //   width: '120px',
  //   height: '40px',
  //   // borderRadius: '15px',
  //   backgroundColor: '#E21450',
  //   border: `1px solid #E21450`,
  //   display: 'flex',
  //   alignItems: 'center',
  //   transition: '0.2s',
  //   '&:hover': {
  //     backgroundColor: '#bc1041',
  //     border: '1px solid #bc1041',
  //     // Reset on touch devices, it doesn't add specificity
  //     '@media (hover: none)': {
  //       backgroundColor: '#bc1041',
  //     },
  //     '&:disabled': {
  //       backgroundColor: '#bc1041',
  //     },
  //   },
  // },
  btnRemoverEmpresa: {
    color: 'white',
    minWidth: '40px',
    height: '40px',
    // borderRadius: '15px',
    backgroundColor: '#FF5896',
    border: `1px solid #FF5896`,
    display: 'flex',
    alignItems: 'center',
    transition: '0.2s',
    '&:hover': {
      backgroundColor: '#DC4E83',
      border: '1px solid #DC4E83',
      // Reset on touch devices, it doesn't add specificity
      // '@media (hover: none)': {
      //   backgroundColor: '#bc1041',
      // },
      // '&:disabled': {
      //   backgroundColor: '#bc1041',
      // },
    },
  },
  btnEmpresaWebsite: {
    color: 'white',
    minWidth: '100px',
    height: '40px',
    // borderRadius: '15px',
    backgroundColor: '#A28DF4',
    border: `1px solid #A28DF4`,
    display: 'flex',
    alignItems: 'center',
    transition: '0.2s',
    '&:hover': {
      backgroundColor: '#856af0',
      border: '1px solid #856af0',
      // Reset on touch devices, it doesn't add specificity
      // '@media (hover: none)': {
      //   backgroundColor: '#bc1041',
      // },
      // '&:disabled': {
      //   backgroundColor: '#bc1041',
      // },
    },
  },
  btnGuardar: {
    color: 'white',
    width: '120px',
    height: '40px',
    // borderRadius: '15px',
    backgroundColor: '#A28DF4',
    border: `1px solid #A28DF4`,
    display: 'flex',
    alignItems: 'center',
    transition: '0.2s',
    '&:hover': {
      backgroundColor: '#856af0',
      border: '1px solid #856af0',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#bc1041',
      },
      '&:disabled': {
        backgroundColor: '#bc1041',
      },
    },
  },
  btnCloseDrawer: {
    color: '#464255',
    width: '30px',
    height: '40px',
    borderRadius: '15px',
    backgroundColor: '#f2f2f2',
    border: `1px solid #f2f2f2`,
    display: 'flex',
    alignItems: 'center',
    transition: '0.2s',
    '&:hover': {
      backgroundColor: '#e0e0e0',
      border: '1px solid #e0e0e0',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#e0e0e0',
      },
    },
  },
  drawerHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  tab: {
    fontWeight: 'bold',
    textTransform: 'capitalize',
  },
});

const schema = yup.object().shape({
  nome: yup.string().required('Preenchimento obrigatório'),
  email: yup
    .string()
    .email('Inisira um email válido')
    .required('Preenchimento obrigatório'),
  contato: yup
    .string()
    .required('Preenchimento obrigatório')
    .matches(/^[0-9]*[()+-[\], ]*$/, 'Caractéres não válidos'),
  empresas: yup
    .array()
    .min(1)
    .of(
      yup.object().shape({
        empresasDropdown: yup
          .object()
          .nullable()
          .required('Preenchimento obrigatório'),
        perfilDropdown: yup
          .object()
          .nullable()
          .required('Preenchimento obrigatório'),
      })
    )
    .required(),
});

const SideDrawer = ({
  state,
  toggleDrawer,
  utilizadores,
  fetchUtilizadores,
  drawerType,
  utilizador,
  setUtilizador,
  validationUtilizador,
  utilizadorId,
  utilizadorInfo,
}) => {
  const classes = useStyles();

  const [guardarLoading, setGuardarLoading] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    resetField,
    formState: { errors },
    getValues,
    watch,
    setValue,
    trigger,
  } = useForm({
    defaultValues: {
      nome: '',
      email: '',
      contato: '',
      empresas: [
        {
          // HEADER
          empresasDropdown: null,
          // FORM
          email: '',
          contato: '',
          perfilDropdown: { id: 3, descricao: 'Utilizador' },
          //ADICIONAL
          cplink: '',
          identificador: '',
        },
      ],
    },
    resolver: yupResolver(schema),
  });

  return (
    <div>
      <Drawer
        anchor={'right'}
        open={state['right']}
        onClose={() => toggleDrawer('right', false)}
        classes={{ paper: classes.paper }}
      >
        <div className={classes.list}>
          {drawerType === 'add' && (
            <FormAdd
              toggleDrawer={toggleDrawer}
              fetchUtilizadores={fetchUtilizadores}
              utilizador={utilizador}
              setUtilizador={setUtilizador}
              validationUtilizador={validationUtilizador}
              utilizadorInfo={utilizadorInfo}
              classes={classes}
              guardarLoading={guardarLoading}
              setGuardarLoading={setGuardarLoading}
              // React hook form
              control={control}
              handleSubmitForm={handleSubmit}
              errors={errors}
              reset={reset}
              resetField={resetField}
              getValues={getValues}
              watch={watch}
              setValue={setValue}
              trigger={trigger}
            />
          )}
          {drawerType === 'edit' && (
            <FormEdit
              toggleDrawer={toggleDrawer}
              utilizadores={utilizadores}
              fetchUtilizadores={fetchUtilizadores}
              utilizador={utilizador}
              setUtilizador={setUtilizador}
              utilizadorId={utilizadorId}
              utilizadorInfo={utilizadorInfo}
              classes={classes}
              guardarLoading={guardarLoading}
              setGuardarLoading={setGuardarLoading}
              // React hook form
              control={control}
              handleSubmitForm={handleSubmit}
              errors={errors}
              reset={reset}
              getValues={getValues}
              watch={watch}
              setValue={setValue}
              trigger={trigger}
            />
          )}
        </div>
      </Drawer>
    </div>
  );
};

export default SideDrawer;
