export const notificationsInitialState = {
  success: {
    isOpen: false,
    message: '',
    type: '',
  },
  warning: {
    isOpen: false,
    message: '',
    type: '',
  },
  error: {
    isOpen: false,
    message: '',
    type: '',
  },
  info: {
    isOpen: false,
    message: '',
    type: '',
  },
};
