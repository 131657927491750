/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@mui/styles';
import { IconButton } from '@mui/material';
import MUIDataTable from 'mui-datatables';

//ICONS
import FeatherIcon from 'feather-icons-react';

//Actions
import GetUtilizadores from '../../../utils/redux/actions/utilizadores/GetUtilizadoresAction';
import GetUtilizador from '../../../utils/redux/actions/utilizadores/GetUtilizadorAction';
import GetPerfis from '../../../utils/redux/actions/utilizadores/GetPerfisAction';

//Components
import CustomToolbar from './components/customToolbar';
import ModalDelete from './components/modalDelete';
import SideDrawer from './components/drawer';
import GetEmpresasUtilizador from '../../../utils/redux/actions/utilizadores/GetEmpresasUtilizadorAction';
import GetEmpresasBaseUtilizador from '../../../utils/redux/actions/utilizadores/GetEmpresasUtilizadorBaseAction';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      // margin: theme.spacing(1),
      width: '25ch',
    },
  },
  tableOptions: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'end',
  },
  tableActivation: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'start',
  },
  paperFilters: {
    background: 'transparent',
    padding: '20px',
    boxShadow: 'none',
  },
}));

const Utilizadores = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [responsive] = useState('standard');
  const [tableBodyHeight] = useState('100%');
  const [tableBodyMaxHeight] = useState('');
  const [openDelete, setOpenDelete] = useState(false);

  const [utilizadorId, setUtilizadorId] = useState();
  const [utilizadorNome, setUtilizadorNome] = useState();
  const [utilizador, setUtilizador] = useState({
    nome: '',
    email: '',
    contato: '',
    empresas: [],
  });

  const resetUtilizador = () => {
    setUtilizador({
      nome: '',
      email: '',
      contato: '',
      empresas: [],
    });
  };

  const [state, setState] = useState({
    right: false,
  });
  const [drawerType, setDrawerType] = useState(null);

  const fetchUtilizadores = () => {
    dispatch(GetUtilizadores());
  };
  const fetchEmpresasDropdown = () => {
    dispatch(GetEmpresasUtilizador());
  };
  // ---- Fetch de todas as empresas base da dropdown,vai servir especialmente para comparar os length entre arrays
  const fetchEmpresasDropdownBase = () => {
    dispatch(GetEmpresasBaseUtilizador());
  };
  const fetchPerfis = () => {
    dispatch(GetPerfis());
  };

  const fetchUtilizador = (id) => {
    dispatch(GetUtilizador(id));
  };

  useEffect(() => fetchUtilizadores(), []);
  useEffect(() => fetchEmpresasDropdown(), []);
  useEffect(() => fetchEmpresasDropdownBase(), []);
  useEffect(() => fetchPerfis(), []);

  const utilizadores = useSelector((state) => state.utilizadores.utilizadores);
  const utilizadorInfo = useSelector((state) => state.utilizadores.utilizador);

  const handleOpen = (btn, e, id, tableMeta) => {
    e.preventDefault();
    setErrorsUtilizador({});
    if (btn === 'delete') {
      setOpenDelete(true);
      setUtilizadorId(id);
      setUtilizadorNome(tableMeta.rowData[1]);
    }
  };

  const handleClose = (btn, e) => {
    e.preventDefault();
    if (btn === 'delete') {
      setOpenDelete(false);
    }
  };

  const toggleDrawer = (anchor, open, userId, ds) => {
    if (open == false) {
      // setUtilizadorId(userId);
      setState({ ...state, [anchor]: open });
      dispatch({
        type: 'GET_UTILIZADOR',
        payload: {
          nome: '',
          email: '',
          contato: '',
          empresas: [],
        },
      });
      fetchEmpresasDropdown();
    }
    if (open == true) {
      resetUtilizador();

      setErrorsUtilizador({});
      setDrawerType(ds);
      setUtilizadorId(userId);
      setState({ ...state, [anchor]: open });
      fetchUtilizador(userId);
    }
  };

  // -------------------------- Validacao unidades --------------------//
  const [errorsUtilizador, setErrorsUtilizador] = useState({});

  const validationUtilizador = (fieldValues = utilizador) => {
    const temp = { ...errorsUtilizador };

    // console.log(fieldValues);

    if ('email' in fieldValues) {
      // prettier-ignore
      temp.email = (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(fieldValues.email) ? '' : 'Coloque um email válido';
    }
    if ('nome' in fieldValues)
      temp.nome = fieldValues.nome ? '' : 'Campo Obrigatório';

    setErrorsUtilizador({
      ...temp,
    });
    if (fieldValues == utilizador) {
      return Object.values(temp).every((x) => x == '');
    }
    return false;
  };

  // -----------------------------------------------------------------------//

  console.log(utilizadores);

  const columns = [
    {
      name: 'id',
      label: 'id',
      options: {
        filter: false,
        sort: true,
        display: false,
      },
    },
    {
      name: 'nome',
      label: 'Nome',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: false,
        sort: true,
      },
    },

    {
      name: 'setup',
      label: 'Ativação',
      options: {
        filter: false,
        sort: true,
        // empty: true,
        setCellProps: () => ({
          align: 'right',
        }),
        customBodyRender: (value, tableMeta) => {
          // console.log(value, tableMeta);
          const activation = tableMeta.rowData[3];
          // console.log(activation);
          return (
            <div className={classes.tableActivation}>
              <>
                {!activation ? (
                  <>
                    {/* 1st try */}
                    <div
                      style={{
                        background: '#b6f9b6',
                        borderRadius: '20px',
                        padding: '6px',
                        fontSize: '12px',
                      }}
                    >
                      <span
                        style={{
                          color: 'green',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <FeatherIcon
                          icon='check-circle'
                          size={20}
                          style={{
                            color: 'green',
                            strokeWidth: '1.5px',
                          }}
                        />
                      </span>
                    </div>
                    {/* 2st try */}
                    {/* <div
                      style={{
                        background: '#b6f9b6',
                        borderRadius: '8px',
                        padding: '5px 8px',
                        fontSize: '12px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <span
                        style={{
                          color: 'green',
                        }}
                      >
                        Confirmado
                      </span>
                    </div> */}
                  </>
                ) : (
                  <>
                    {/* 1st try */}
                    <div
                      style={{
                        background: '#fdb7af',
                        borderRadius: '20px',
                        padding: '6px',
                        fontSize: '12px',
                      }}
                    >
                      <span
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <FeatherIcon
                          icon='check-circle'
                          size={20}
                          style={{
                            color: '#ed3937',
                            strokeWidth: '1.5px',
                          }}
                        />
                      </span>
                    </div>
                    {/* 2st try */}
                    {/* <div
                      style={{
                        background: '#fdb7af',
                        borderRadius: '8px',
                        padding: '5px 8px',
                        fontSize: '12px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <span
                        style={{
                          color: '#ed3937',
                        }}
                      >
                        Por Confirmar
                      </span>
                    </div> */}
                  </>
                )}
              </>
            </div>
          );
        },
      },
    },
    {
      name: 'empresa',
      label: 'Empresa',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: '',
      label: '',
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellProps: () => ({
          align: 'center',
        }),
        customBodyRender: (value, tableMeta) => {
          const userId = tableMeta.rowData[0];

          return (
            <div className={classes.tableOptions}>
              <IconButton
                style={{
                  padding: 6,
                }}
                // onClick={(e) => handleOpen('edit', e, id, tableMeta)}
                onClick={() => toggleDrawer('right', true, userId, 'edit')}
              >
                <FeatherIcon
                  icon='edit'
                  size={20}
                  style={{ strokeWidth: '1.5' }}
                />
              </IconButton>

              <IconButton
                style={{
                  padding: 6,
                }}
                onClick={(e) => handleOpen('delete', e, userId, tableMeta)}
              >
                <FeatherIcon
                  icon='trash-2'
                  size={20}
                  style={{ strokeWidth: '1.5' }}
                />
              </IconButton>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filter: true,
    filterType: 'dropdown',
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    selectableRows: 'none',
    viewColumns: false,
    elevation: 5,
    pagination: true,
    print: false,
    download: false,
    rowsPerPage: 50,
    rowsPerPageOptions: [50, 100, 150, 200],
    // setRowProps: (row, dataIndex, rowIndex) => {
    //   return {
    //     style:
    //       rowIndex % 2
    //         ? { backgroundColor: 'transparent' }
    //         : { backgroundColor: '#F3F5F8' },
    //   };
    // },
    textLabels: {
      body: {
        noMatch: 'Sem Registos encontrados',
        // toolTip: 'Dia',
        columnHeaderTooltip: (column) => `${column.label}`,
      },
      toolbar: {
        search: 'Pesquisar',
      },
      pagination: {
        next: 'Proxima Página',
        previous: 'Página Anterior',
        rowsPerPage: 'Mostrar:',
        displayRows: 'de',
      },
    },

    customToolbar: () => {
      return (
        <>
          <CustomToolbar
            handleOpen={handleOpen}
            onClose={handleClose}
            toggleDrawer={toggleDrawer}
          />
        </>
      );
    },
  };

  return (
    <>
      <ModalDelete
        openDelete={openDelete}
        handleClose={handleClose}
        utilizadorId={utilizadorId}
        fetchUtilizadores={fetchUtilizadores}
        utilizadorNome={utilizadorNome}
      />
      <SideDrawer
        state={state}
        toggleDrawer={toggleDrawer}
        utilizadores={utilizadores}
        fetchUtilizadores={fetchUtilizadores}
        drawerType={drawerType}
        utilizador={utilizador}
        setUtilizador={setUtilizador}
        validationUtilizador={validationUtilizador}
        utilizadorId={utilizadorId}
        utilizadorInfo={utilizadorInfo}
      />
      <div
        style={{
          display: 'flex',
          height: '94vh',
          flexDirection: 'column',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        {/* <div style={{ padding: '40px', width: 'calc(100vw - 55px)' }}> */}
        <div style={{ padding: '40px' }}>
          <MUIDataTable
            title={
              <span
                style={{
                  fontSize: '20px',
                  fontWeight: 'bold',
                }}
                className='text-underline'
              >
                Utilizadores
              </span>
            }
            data={utilizadores || []}
            columns={columns}
            options={options}
          />
        </div>
        <div style={{ padding: '20px 40px' }}>{/* <FooterLinks /> */}</div>
      </div>
    </>
  );
};

export default Utilizadores;
