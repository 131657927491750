/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';

//PACKAGES
import { Button } from '@mui/material';

//ACTIONS
import RecuperarSenha from '../../../../utils/redux/actions/senha/recuperarSenhaAction';
import ShowNotification from '../../../../utils/redux/actions/global/notifications/NotificationAction';

const FormRecuperarSenha = ({
  validateOnChange = true,
  dispatch,
  CssTextField,
  classes,
}) => {
  const [user, setUser] = useState({
    email: '',
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });

    if (validateOnChange) validationUser({ [e.target.name]: e.target.value });
  };

  //--------------------------------- validation user--------------------------/
  const [errorsUser, setErrorsUser] = useState({});
  const validationUser = (fieldValues = user) => {
    const temp = { ...errorsUser };

    if ('email' in fieldValues)
      // prettier-ignore
      temp.email = (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(fieldValues.email) ? '' : 'Campo Obrigatório';

    setErrorsUser({
      ...temp,
    });
    if (fieldValues == user) {
      return Object.values(temp).every((x) => x == '');
    }
    return false;
  };
  //--------------------------------------------------------------------------/

  const [counter, setCounter] = useState({
    time: 1,
    do: false,
    complete: false,
    running: false,
  });

  const resetCounter = () => {
    setCounter({
      time: 1,
      do: false,
      complete: false,
      running: false,
    });
  };

  useEffect(() => {
    if (counter.running == true) {
      let finished = false;
      if (counter.time === 0) {
        finished = true;
        navigate('/');
        ShowNotification('success', 'Irá ser redirecionado...');
        return resetCounter();
      }
      counter.time > 0 && ShowNotification();
      ShowNotification('success', 'Irá ser redirecionado...');
      setTimeout(
        () =>
          setCounter({
            ...counter,
            time: counter.time - 1,
            complete: finished,
          }),
        1000
      );
    }
  }, [counter.time, counter.running]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validationUser()) {
      const ydata = [
        {
          email: user.email,
        },
      ];

      const message = {
        isOpen: true,
        message: 'Senha recuperada com sucesso!!',
        type: 'success',
      };
      const errorMessage = {
        isOpen: true,
        message: 'Ocorreu um erro!!',
        type: 'error',
      };

      dispatch(
        RecuperarSenha(
          ydata,
          navigate,
          counter,
          setCounter,
          ShowNotification,
          message,
          errorMessage
        )
      ).then(() =>
        setCounter({
          ...counter,
          running: true,
        })
      );
    }
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <form
          className='loginForm'
          noValidate
          autoComplete='off'
          // onSubmit={handleSubmit}
        >
          <CssTextField
            id='email'
            name='email'
            label='Email'
            variant='outlined'
            // className='emailInput'

            size='small'
            value={user.email}
            onChange={handleChange}
            {...(errorsUser.email && {
              error: true,
              helperText: errorsUser.email,
            })}
          />
          <div className='login-submit-section'>
            <Button
              variant='contained'
              color='primary'
              type='submit'
              onClick={handleSubmit}
              className={classes.btnGuardar}
            >
              Confirmar
            </Button>

            <Link to='/' style={{ color: 'black' }}>
              <p>Voltar para o Login</p>
            </Link>
          </div>
        </form>
      </div>
    </>
  );
};

export default FormRecuperarSenha;
