import { empresasInitialState } from '../initialState/empresas';

const empresasReducer = (state = empresasInitialState, { type, payload }) => {
  switch (type) {
    case 'GET_EMPRESAS':
      return {
        ...state,
        empresas: payload,
      };
    case 'GET_EMPRESA':
      return {
        ...state,
        empresa: payload,
      };
    case 'POST_EMPRESA':
      return {
        ...state,
        empresaAdicionada: payload,
      };
    case 'PUT_EMPRESA':
      return {
        ...state,
        empresaEditada: payload,
      };
    case 'DELETE_EMPRESA':
      return {
        ...state,
        empresaRemovida: payload,
      };

    // CLEAN STATE
    case 'USER_LOGOUT':
      return {
        ...empresasInitialState,
      };

    default:
      return state;
  }
};

export default empresasReducer;
