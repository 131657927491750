import React, { useState } from 'react';
import { Button, Typography } from '@mui/material';
import FeatherIcon from 'feather-icons-react';

const FileUploaderImageProfile = ({
  classes,
  utilizador,
  newFile,
  setNewFile,
}) => {
  const [showImage, setShowImage] = useState(false);

  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      var reader = new FileReader();
      // eslint-disable-next-line no-unused-vars
      var url = reader.readAsDataURL(file);

      reader.onloadend = () => {
        setNewFile({
          imgSrc: [reader.result],
          file: file,
        });
      };
    }
    e.target.value = '';
  };

  const fileType =
    utilizador &&
    utilizador.foto &&
    utilizador.foto.split('/')[4].split('.')[1];

  return (
    <>
      <Typography fontSize={13}>Foto de perfil</Typography>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div>
          {newFile &&
          Object.values(newFile) &&
          Object.values(newFile).length > 0 ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginLeft: '8px',
                height: '33px',
              }}
            >
              <div style={{ width: '100%', display: 'flex' }}>
                <FeatherIcon
                  icon='x-square'
                  size={20}
                  style={{
                    strokeWidth: '1.5',
                    marginRight: '15px',
                    cursor: 'pointer',
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    setNewFile({});
                    setShowImage(false);
                  }}
                />

                <p style={{ margin: 0, fontSize: '14px' }}>
                  {console.log(newFile)}
                  {newFile &&
                  newFile.file &&
                  newFile.file.name &&
                  Object.values(newFile.file.name).length > 25
                    ? `${
                        newFile &&
                        newFile.file &&
                        newFile.file.name &&
                        newFile.file.name.substring(0, 25)
                      } ...`
                    : newFile && newFile.file && newFile.file.name}
                  {utilizador && utilizador.foto && newFile && !newFile.file
                    ? `${utilizador.nome}.${fileType}`
                    : null}
                </p>
              </div>
              <div
                style={{
                  height: '20px',
                }}
              >
                <FeatherIcon
                  icon={showImage ? 'eye' : 'eye-off'}
                  size={20}
                  style={{
                    strokeWidth: '1.5',
                    cursor: 'pointer',
                  }}
                  onClick={() => setShowImage(!showImage)}
                />
              </div>
            </div>
          ) : (
            <Button className={classes.btnCarregarImagem}>
              <label htmlFor='propostas' className='custom-file-upload'>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'start',
                    cursor: 'pointer',
                  }}
                >
                  <FeatherIcon
                    icon='image'
                    size={20}
                    style={{ strokeWidth: '1.5', marginRight: '5px' }}
                  />
                  <p style={{ margin: '0px 10px', fontSize: '12px' }}>
                    Carregar Fotografia
                  </p>
                </div>
              </label>
            </Button>
          )}

          <input
            type='file'
            name='propostas'
            id='propostas'
            hidden
            onChange={(e) => handleFileChange(e)}
            multiple
            // accept='.jpg'
          />
        </div>
        <div className='profileImage' style={{ margin: 'auto' }}>
          {newFile && newFile.imgSrc && showImage ? (
            <img
              src={newFile.imgSrc}
              alt=''
              style={{ width: '200px', borderRadius: '10px' }}
            />
          ) : (
            <>
              {showImage && (
                <img
                  src={utilizador.foto}
                  alt=''
                  style={{ width: '200px', borderRadius: '10px' }}
                />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default FileUploaderImageProfile;
