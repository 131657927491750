import { notificationsInitialState } from '../initialState/notifications';

const notificationsReducer = (
  state = notificationsInitialState,
  { type, payload }
) => {
  switch (type) {
    case 'SNACKBAR_SUCCESS':
      return {
        ...state,
        success: payload,
      };
    case 'SNACKBAR_WARNING':
      return {
        ...state,
        warning: payload,
      };
    case 'SNACKBAR_ERROR':
      return {
        ...state,
        error: payload,
      };
    case 'SNACKBAR_INFO':
      return {
        ...state,
        info: payload,
      };
    case 'SNACKBAR_CLEAR':
      return {
        ...state,
        success: {
          isOpen: false,
          message: '',
          type: '',
        },
        warning: {
          isOpen: false,
          message: '',
          type: '',
        },
        error: {
          isOpen: false,
          message: '',
          type: '',
        },
        info: {
          isOpen: false,
          message: '',
          type: '',
        },
      };

    default:
      return state;
  }
};

export default notificationsReducer;
