import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Controller } from 'react-hook-form';
import { Button, TextField, Paper, IconButton } from '@mui/material';

import LoadingButton from '@mui/lab/LoadingButton';

//Icons
import SaveIcon from '@mui/icons-material/Save';
import FeatherIcon from 'feather-icons-react';

//Actions
import ShowNotification from '../../../../../utils/redux/actions/global/notifications/NotificationAction';
import GetPerfis from '../../../../../utils/redux/actions/utilizadores/GetEmpresasUtilizadorAction';
import AdicionarUtilizador from '../../../../../utils/redux/actions/utilizadores/adicionarUtilizadorActions';

//Components
import Spinner from '../../../../../Global/Components/Spinner';
import FileUploaderImageProfile from './components/fileUploaderImageProfile';

import EmpresaCard from './empresaCard';

const FormAdd = ({
  toggleDrawer,
  fetchUtilizadores,
  utilizador,
  setUtilizador,
  validationUtilizador,
  utilizadorInfo,
  validateOnChange = true,
  classes,
  guardarLoading,
  setGuardarLoading,
  // React hook form
  control,
  handleSubmitForm,
  errors,
  reset,
  resetField,
  getValues,
  watch,
  setValue,
  trigger,
}) => {
  const dispatch = useDispatch();

  // Limpa form e erros quando se abre o drawer
  useEffect(() => reset(), []);

  // ---- Este bloco vai buscar um array de todas as empresas, sem haver quaisquer mudanças ao mesmo, e vai comparar a quantidade de empresas adicionadas com essa base ---- //

  let empresasDropdownBase = useSelector(
    (state) => state.utilizadores.empresasDropdownBase
  );

  let blockAddEmpresa = null;

  if (utilizador?.empresas?.length < empresasDropdownBase?.length - 1) {
    blockAddEmpresa = false;
  } else {
    blockAddEmpresa = true;
  }

  // ------- //

  // Valida se todos os campos de perfil estao preenchidos
  const checkPerfilValidation = getValues()?.empresas?.every(
    (empresa, index) => !errors?.empresas?.[index]?.perfilDropdown
  );

  const [openedCards, setOpenedCards] = useState(false);

  // FILE UPLOAD

  const [newFile, setNewFile] = useState([]);

  const perfis = useSelector((state) => state.utilizadores.perfis);

  // console.log(perfis);
  const handleChangePerfisId = (value) => {
    // e.preventDefault();
    dispatch(GetPerfis(value && value.id));
    if (validateOnChange) validationUtilizador({ perfisId: value });
  };
  // ---------------- ADIÇÃO DE NOVA EMPRESA -------------------- //

  const handleAddEmpresa = async () => {
    const empresasLength = utilizador.empresas.length;

    const novaEmpresa = {
      // HEADER
      empresasDropdown: null,
      // FORM
      email: '',
      contato: '',
      perfilDropdown: { id: 3, descricao: 'Utilizador' },
    };

    utilizador.empresas.push(novaEmpresa);

    // console.log(novaEmpresa);

    // console.log(utilizador);

    await setUtilizador({ ...utilizador });

    // Vai garantir que o proximo utilizador a ser adicionado ao formulário, vai ter todos os valores default, pois se fizessemos reset, teriamos de fazer reset a todo o form quando adicionamos um novo card, o que ia eliminar todos os dados que já estavam registados

    await setValue(`empresas[${empresasLength}][perfilDropdown]`, {
      id: 3,
      descricao: 'Utilizador',
    });
  };

  // console.log(watch('empresas'));
  // ---------------------------------------------------------- //

  const onSubmit = (data) => {
    const empresasData = data.empresas.map((empresa) => ({
      email: empresa.email,
      contato: empresa.contato,
      cargo: empresa.cargo,
      empresaId: empresa.empresasDropdown && empresa.empresasDropdown.id,
      perfilId: empresa.perfilDropdown && empresa.perfilDropdown.id,
      //ADICIONAL
      cplink: null,
      identificador: null,
    }));

    const ydata = [
      {
        nome: data.nome,
        email: data.email,
        contato: data.contato,
        empresas: empresasData,
        // perfilId: data.perfilDropdown && data.perfilDropdown.id,
      },
    ];

    const message = {
      isOpen: true,
      message: 'Utilizador adicionado com sucesso!!',
      type: 'success',
    };
    const errorMessage = {
      isOpen: true,
      message: 'Ocorreu um erro!! O utilizador não foi registado.',
      type: 'error',
    };

    console.log(ydata);

    setGuardarLoading(true);

    dispatch(
      AdicionarUtilizador(
        ydata,
        newFile && newFile.file,
        ShowNotification,
        message,
        errorMessage,
        toggleDrawer
      )
    )
      .then(() => fetchUtilizadores())
      .then(() => setGuardarLoading(false));
  };

  return (
    <>
      {utilizador ? (
        <div
          style={{
            isplay: 'flex',
            flexDirection: 'column',
            padding: '40px',
            height: '100%',
          }}
        >
          <form onSubmit={handleSubmitForm(onSubmit)}>
            <div className={classes.drawerHeader}>
              <div
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                }}
                onClick={() => toggleDrawer('right', false)}
              >
                <div>
                  <FeatherIcon
                    icon='chevron-left'
                    size={20}
                    style={{ strokeWidth: '1.5' }}
                  />
                </div>
                <div className='text-underline-close-drawer'>
                  Lista de Utilizadores
                </div>
              </div>

              {!guardarLoading ? (
                <Button className={classes.btnGuardar} type='submit'>
                  Guardar
                </Button>
              ) : (
                <LoadingButton
                  color='secondary'
                  loading={guardarLoading}
                  loadingPosition='start'
                  startIcon={<SaveIcon />}
                  variant='contained'
                  type='submit'
                  className={classes.btnGuardar}
                >
                  Guardar
                </LoadingButton>
              )}
            </div>
            <Paper sx={{ px: 6, py: 2, mt: 4 }}>
              <h4
                style={{
                  fontSize: '22px',
                  width: '100%',
                  marginBottom: '0px',
                }}
                className='text-underline'
              >
                Dados do utilizador
              </h4>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                }}
              >
                <h4
                  style={{ color: '#E21450', width: '100%' }}
                  className='text-underline-title-drawer '
                >
                  Informações pessoais
                </h4>
                <Controller
                  name='nome'
                  control={control}
                  render={({ field }) => (
                    <>
                      <TextField
                        label='Nome'
                        color='primary'
                        variant='outlined'
                        size='small'
                        {...field}
                        fullWidth
                        style={{ marginTop: '10px', width: '59%' }}
                        error={!!errors.nome}
                        helperText={errors.nome ? errors.nome?.message : ''}
                      />
                    </>
                  )}
                />

                <span style={{ width: '2%' }} />
                <Controller
                  name='contato'
                  control={control}
                  render={({ field }) => (
                    <>
                      <TextField
                        label='Contato'
                        color='primary'
                        variant='outlined'
                        size='small'
                        {...field}
                        fullWidth
                        style={{ marginTop: '10px', width: '39%' }}
                        error={!!errors.contato}
                        helperText={
                          errors.contato ? errors.contato?.message : ''
                        }
                      />
                    </>
                  )}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                }}
              >
                <Controller
                  name='email'
                  control={control}
                  render={({ field }) => (
                    <>
                      <TextField
                        label='Email'
                        color='primary'
                        variant='outlined'
                        size='small'
                        {...field}
                        fullWidth
                        style={{ marginTop: '10px', width: '59%' }}
                        error={!!errors.email}
                        helperText={errors.email ? errors.email?.message : ''}
                      />
                    </>
                  )}
                />
                <span style={{ width: '2%' }} />
                <div style={{ width: '39%' }}>
                  <FileUploaderImageProfile
                    classes={classes}
                    utilizador={utilizador}
                    newFile={newFile}
                    setNewFile={setNewFile}
                  />
                </div>
                {/* <Controller
                  name='email'
                  control={control}
                  render={({ field }) => (
                    <>
                      <TextField
                        label='Email'
                        color='primary'
                        variant='outlined'
                        size='small'
                        {...field}
                        fullWidth
                        style={{ marginTop: '10px', width: '39%' }}
                        error={!!errors.email}
                        helperText={errors.email ? errors.email?.message : ''}
                      />
                    </>
                  )}
                /> */}
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <div>
                    <h4
                      style={{ color: '#E21450', width: '100%' }}
                      className='text-underline-title-drawer '
                    >
                      Empresas
                    </h4>
                  </div>

                  <div
                    // eslint-disable-next-line eqeqeq
                    onClick={blockAddEmpresa == false ? handleAddEmpresa : null}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      // eslint-disable-next-line eqeqeq
                      color: blockAddEmpresa == false ? '#333333' : '#bdbdbd',
                    }}
                  >
                    Associar Empresas{' '}
                    <IconButton
                      className={classes.iconButton}
                      // onClick={this.handleClick}
                      disabled={blockAddEmpresa}
                    >
                      <FeatherIcon
                        icon='plus-square'
                        size={22}
                        className={classes.deleteIcon}
                        style={{ strokeWidth: '2' }}
                      />
                    </IconButton>
                  </div>
                </div>

                {((errors && errors.empresas && errors.empresas.length > 0) ||
                  (errors &&
                    errors.empresas &&
                    errors.empresas.message ===
                      'empresas field must have at least 1 items')) &&
                utilizador &&
                utilizador.empresas &&
                utilizador.empresas.length <= 0 ? (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <FeatherIcon
                      icon='alert-circle'
                      size={15}
                      style={{
                        strokeWidth: '2',
                        color: '#d32f2f',
                        marginRight: '5px',
                      }}
                    />
                    <p
                      style={{
                        color: '#d32f2f',
                        fontSize: '12px',
                        marginTop: '13px',
                      }}
                    >
                      É necessário adicionar uma empresa
                    </p>
                  </div>
                ) : null}

                {checkPerfilValidation == true ? null : (
                  <>
                    {!openedCards && (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <FeatherIcon
                          icon='alert-circle'
                          size={15}
                          style={{
                            strokeWidth: '2',
                            color: '#d32f2f',
                            marginRight: '5px',
                          }}
                        />
                        <p
                          style={{
                            color: '#d32f2f',
                            fontSize: '12px',
                            marginTop: '13px',
                          }}
                        >
                          Existem campos obrigatórios dentro das empresas por
                          preencher
                        </p>
                      </div>
                    )}
                  </>
                )}

                {utilizador &&
                  utilizador.empresas &&
                  utilizador.empresas.map((empresa, index) => (
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                      key={index}
                    >
                      <EmpresaCard
                        utilizador={utilizador}
                        setUtilizador={setUtilizador}
                        errors={errors}
                        reset={reset}
                        index={index}
                        control={control}
                        watch={watch}
                        getValues={getValues}
                        empresas={utilizador && utilizador.empresas}
                        classes={classes}
                        handleChangePerfisId={handleChangePerfisId}
                        perfis={perfis}
                        setOpenedCards={setOpenedCards}
                      />
                    </div>
                  ))}
              </div>

              {/* <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '30px',
                }}
              >
                {!guardarLoading ? (
                  <Button className={classes.btnGuardar} type='submit'>
                    Guardar
                  </Button>
                ) : (
                  <LoadingButton
                    color='secondary'
                    loading={guardarLoading}
                    loadingPosition='start'
                    startIcon={<SaveIcon />}
                    variant='contained'
                    type='submit'
                    className={classes.btnGuardar}
                  >
                    Guardar
                  </LoadingButton>
                )}
              </div> */}
            </Paper>
          </form>
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner />
        </div>
      )}
    </>
  );
};

export default FormAdd;
