import Instance from '../../../Axios';

const RemoverEmpresa =
  (empresaId, ShowNotification, message, errorMessage, handleClose) =>
  async (dispatch) => {
    await Instance()
      .delete(`/gestao/empresas/${empresaId}`)
      .then(({ data }) => {
        console.log(data);
        dispatch({ type: 'DELETE_EMPRESA', payload: data });
        dispatch(ShowNotification(message));

        // toggleDrawer('right', false);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response === 403) {
            dispatch(ShowNotification(errorMessage));
          } else {
            dispatch(ShowNotification(errorMessage));
          }
        }
      });
  };

export default RemoverEmpresa;
