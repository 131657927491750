import { senhaInitialState } from '../initialState/senha';

const senhaReducer = (state = senhaInitialState, { type, payload }) => {
  switch (type) {
    case 'GET_DEF_SENHA':
      return {
        ...state,
        dadosDefSenha: payload,
      };
    case 'DEFINIR_SENHA':
      return {
        ...state,
        senhaDefinida: payload,
      };
    case 'CLEAR_DADOS':
      return {
        ...state,
        dadosDefSenha: [],
      };

    default:
      return state;
  }
};

export default senhaReducer;
