import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
//PACKAGES
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import {
  Menu,
  MenuItem,
  CssBaseline,
  AppBar,
  Toolbar,
  IconButton,
} from '@mui/material';

// import Logo from '../assets/images/jafConstrucoesLogo.png';
// import { FaRegUserCircle } from 'react-icons/fa';
// import FeatherIcon from 'feather-icons-react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { persistor } from '../utils/store';

import theme from './css/MuiTheme/theme';

import UserIcon from '../assets/images/icons/user.svg';
import CardIcon from '../assets/images/icons/cardIcon.svg';

//COMPONENTS
// import SwipableDrawer from './SwipableDrawer';
import { ReactSVG } from 'react-svg';

const drawerWidth = 240;

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
  },

  appBar: {
    // transition: theme.transitions.create(['margin', 'width'], {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.leavingScreen,
    // }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    // transition: theme.transitions.create(['margin', 'width'], {
    //   easing: theme.transitions.easing.easeOut,
    //   duration: theme.transitions.duration.enteringScreen,
    // }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    // ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    // transition: theme.transitions.create('margin', {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.leavingScreen,
    // }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    // transition: theme.transitions.create('margin', {
    //   easing: theme.transitions.easing.easeOut,
    //   duration: theme.transitions.duration.enteringScreen,
    // }),
    marginLeft: 0,
  },
}));

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const TopAppbar = ({ children }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  // const theme = useTheme();
  const [open] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open1 = Boolean(anchorEl);
  // eslint-disable-next-line no-unused-vars
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [item, setItem] = useState({
    selected: null,
  });
  const user = useSelector((state) => state.user);

  const updateSelected = (selectedIndex) => {
    setItem({ selected: selectedIndex });
  };

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const menuLink = location.pathname.split('/')[1];

  useEffect(() => {
    if (menuLink === 'construcoes') {
      updateSelected(0);
    } else if (menuLink === 'intervencoes') {
      updateSelected(1);
    } else if (menuLink === 'energias') {
      updateSelected(2);
    } else if (menuLink === 'utilizadores') {
      updateSelected(3);
    } else if (menuLink === 'dashboard') {
      updateSelected(10);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuLink]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSair = () => {
    // setAnchorEl(null);

    localStorage.removeItem('token');
    localStorage.removeItem('persist:root');
    navigate('/');
    persistor.purge();
    dispatch({ type: 'USER_LOGOUT' });
    // rootReducer(store, { type: 'USER_LOGOUT' });
  };

  const width = getWindowDimensions().width;
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position='fixed'
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
        elevation={0}
        style={{
          backgroundColor: 'white',
        }}
      >
        <Toolbar
          style={{
            backgroundColor: 'white',
            display: 'flex',
            justifyContent: 'space-between',
            height: '60px',
            color: '#707070',
            boxShadow: '#ededed 0px 4px 10px',
          }}
        >
          <div
            style={{
              marginLeft: '-10px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Link to='/'>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <img
                  src={CardIcon}
                  alt=''
                  style={{
                    width: '40px',
                  }}
                  onClick={() => updateSelected(10)}
                />
                <span
                  style={{
                    marginLeft: '20px',
                    fontSize: '20px',
                    textDecoration: 'none',
                    color: '#000000',
                  }}
                >
                  Gestão de Cartões Pessoais
                </span>
              </div>
            </Link>
          </div>

          {/* <div style={{ display: 'flex', marginRight: '15px' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {width > 1100 ? (
                <>
                  {user &&
                    user.permissoes &&
                    user.permissoes.includes('APP_CONSTRUCOES') && (
                      <>
                        <Link
                          to='/construcoes/obras'
                          style={{
                            color: '#929CA6',
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                          onClick={() => updateSelected(0)}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <FeatherIcon
                              icon='box'
                              size={35}
                              style={
                                item.selected === 0
                                  ? { color: '#E21450', strokeWidth: '1px' }
                                  : { color: '#333', strokeWidth: '1px' }
                              }
                            />
                            <span
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                marginLeft: '15px',
                                color: '#000000',
                              }}
                            >
                              <p
                                style={{
                                  margin: 0,
                                  fontSize: '16px',
                                  fontWeight: 'bold',
                                }}
                              >
                                Gestão Aeroportuária
                              </p>
                              <p
                                style={{
                                  margin: 0,
                                  fontSize: '14px',
                                }}
                              >
                                JAF Construções
                              </p>
                            </span>
                          </div>
                        </Link>
                        <span
                          style={{
                            background: '#D6DFE8',
                            height: '30px',
                            width: '2px',
                            margin: '0px 10px',
                          }}
                        />
                      </>
                    )}

                  {user &&
                    user.permissoes &&
                    user.permissoes.includes('APP_INTERVENCOES') && (
                      <>
                        <Link
                          to={
                            user.perfilId === 3
                              ? '/intervencoes/lista'
                              : '/intervencoes/dashboard'
                          }
                          style={{
                            color: '#929CA6',
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                          onClick={() => updateSelected(1)}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <FeatherIcon
                              icon='tool'
                              size={35}
                              style={
                                item.selected === 1
                                  ? { color: '#E21450', strokeWidth: '1px' }
                                  : { color: '#333', strokeWidth: '1px' }
                              }
                            />
                            <span
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                marginLeft: '15px',
                                color: '#000000',
                              }}
                            >
                              <p
                                style={{
                                  margin: 0,
                                  fontSize: '16px',
                                  fontWeight: 'bold',
                                }}
                              >
                                Intervenções
                              </p>
                              <p
                                style={{
                                  margin: 0,
                                  fontSize: '14px',
                                }}
                              >
                                JAF Construções
                              </p>
                            </span>
                          </div>
                        </Link>
                        <span
                          style={{
                            background: '#D6DFE8',
                            height: '30px',
                            width: '2px',
                            margin: '0px 10px',
                          }}
                        />
                      </>
                    )}

                  {user &&
                    user.permissoes &&
                    user.permissoes.includes('APP_ENERGIAS') && (
                      <>
                        <Link
                          to='/energias/dashboard'
                          style={{
                            color: '#929CA6',
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                          onClick={() => updateSelected(2)}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <FeatherIcon
                              icon='zap'
                              size={35}
                              style={
                                item.selected === 2
                                  ? { color: '#E21450', strokeWidth: '1px' }
                                  : { color: '#333', strokeWidth: '1px' }
                              }
                            />
                            <span
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                marginLeft: '15px',
                                color: '#000000',
                              }}
                            >
                              <p
                                style={{
                                  margin: 0,
                                  fontSize: '16px',
                                  fontWeight: 'bold',
                                }}
                              >
                                Gestão de Obra
                              </p>
                              <p
                                style={{
                                  margin: 0,
                                  fontSize: '14px',
                                }}
                              >
                                JAF Energias
                              </p>
                            </span>
                          </div>
                        </Link>
                        <span
                          style={{
                            background: '#D6DFE8',
                            height: '30px',
                            width: '2px',
                            margin: '0px 10px',
                          }}
                        />
                      </>
                    )}

                  {user &&
                    user.permissoes &&
                    user.permissoes.includes('APP_UTILIZADORES') && (
                      <>
                        <Link
                          to='/utilizadores'
                          onClick={() => updateSelected(3)}
                          style={{ color: '#929CA6' }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <FeatherIcon
                              icon='users'
                              size={35}
                              style={
                                item.selected === 3
                                  ? { color: '#E21450', strokeWidth: '1px' }
                                  : { color: '#333', strokeWidth: '1px' }
                              }
                            />
                            <span
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                marginLeft: '15px',
                                color: '#000000',
                              }}
                            >
                              <p
                                style={{
                                  margin: 0,
                                  fontSize: '16px',
                                  fontWeight: 'bold',
                                }}
                              >
                                Utilizadores
                              </p>
                              <p
                                style={{
                                  margin: 0,
                                  fontSize: '14px',
                                }}
                              >
                                Gestão
                              </p>
                            </span>
                          </div>
                        </Link>
                        <span
                          style={{
                            background: '#D6DFE8',
                            height: '30px',
                            width: '2px',
                            margin: '0px 10px',
                          }}
                        />
                      </>
                    )}
                </>
              ) : null}
            </div>

            <div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <>
                  {width > 700 && (
                    <>
                      {(item && item.selected === 0) ||
                      (item && item.selected === 1) ? (
                        <>
                          <p style={{ color: '#000000' }}>
                            {user && user.aeroportoAbreviatura}
                          </p>

                          <span
                            style={{
                              background: '#D6DFE8',
                              height: '30px',
                              width: '2px',
                              margin: '0px 10px',
                            }}
                          />
                        </>
                      ) : (
                        <>
                          <span style={{ minWidth: '200px' }} />
                        </>
                      )}

                      <p style={{ color: '#000000' }}>{user && user.nome}</p>
                    </>
                  )}
                </>

                <IconButton
                  aria-label='account of current user'
                  aria-controls='menu-appbar'
                  aria-haspopup='true'
                  onClick={handleMenu}
                  color='inherit'
                >
                  <FaRegUserCircle color='#929CA6' />
                </IconButton>

                <Menu
                  id='menu-appbar'
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={open1}
                  onClose={handleClose}
                >
                  <div>
                    <MenuItem onClick={handleSair}>Sair</MenuItem>
                  </div>
                </Menu>
              </div>
            </div>
          </div> */}
          <IconButton
            aria-label='account of current user'
            aria-controls='menu-appbar'
            aria-haspopup='true'
            onClick={handleMenu}
            color='inherit'
          >
            <img src={UserIcon} style={{ height: '25px' }} alt='' />
          </IconButton>

          <Menu
            id='menu-appbar'
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={open1}
            onClose={handleClose}
          >
            <div>
              <MenuItem onClick={handleSair}>Sair</MenuItem>
            </div>
          </Menu>
        </Toolbar>
      </AppBar>

      <main>
        <div className={classes.drawerHeader} style={{ marginTop: '50px' }} />
        {children}
      </main>
    </div>
  );
};

export default TopAppbar;
