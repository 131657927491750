import { authInitialState } from '../initialState/auth';

const authReducer = (state = authInitialState, { type, payload }) => {
  switch (type) {
    case 'SIGN_IN':
      return {
        ...state,
        ...payload,
        // nome: payload.user.nome,
        // id: payload.user.id,
        // role: payload.user.perfis_id,
        // permissoes: payload.user.permissoes,
        // aeroporto: payload.user.aeroportosDescricao,
        // aeroportoFilter: payload.user.aeroportosDropdown,
        // aeroportoAbreviatura: payload.user.aeroportoAbreviatura,
        // lingua: payload.user.aeroportoLingua,
        // perfilId: payload.user.perfisId,
        // energParceiroId: payload.user.energParceirosId,
      };
    case 'USER_LOGOUT':
      return {
        ...authInitialState,
      };

    default:
      return state;
  }
};

export default authReducer;
