import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import FooterDrawer from './components/drawer';

// ---------------------------- Window dimensions -------------------------//

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

const FooterLinks = () => {
  const [drawerState, setDrawerState] = useState('');
  const dimensions = useWindowDimensions();
  const local = useLocation().pathname.split('/')[1];
  const [state, setState] = useState({
    right: false,
  });

  let activeClassname = '';

  if (
    (local === '' || local === 'recuperarSenha' || local === 'definirsenha') &&
    (dimensions && dimensions.width) < 800
  ) {
    activeClassname = 'link-div-white';
  } else {
    activeClassname = 'link-div';
  }

  const toggleDrawer = (anchor, open, page) => {
    console.log(anchor, open, page);
    // eslint-disable-next-line eqeqeq
    if (open == false) {
      setState({ ...state, [anchor]: open });
    }
    // eslint-disable-next-line eqeqeq
    if (open == true) {
      console.log('abre o drawer');
      setDrawerState(page);
      setState({ ...state, [anchor]: open });
    }
  };

  return (
    <div>
      <FooterDrawer
        state={state}
        toggleDrawer={toggleDrawer}
        drawerState={drawerState}
      />
      <div
        style={{
          display: 'flex',
          flexDirection:
            dimensions && dimensions.width < 860 ? 'column' : 'row',
        }}
      >
        <div
          className={activeClassname}
          onClick={() => toggleDrawer('right', true, 'condicoesUso')}
        >
          Condições de uso
        </div>
        <span style={{ width: '20px' }} />
        <div
          className={activeClassname}
          onClick={() => toggleDrawer('right', true, 'politicasPrivacidade')}
        >
          Políticas de privacidade
        </div>
        <span style={{ width: '20px' }} />
        <div
          className={activeClassname}
          onClick={() => toggleDrawer('right', true, 'politicasCookies')}
        >
          Políticas de cookies
        </div>
      </div>
    </div>
  );
};

export default FooterLinks;
