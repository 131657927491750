import Instance from '../../../Axios';

const AdicionarUtilizador =
  (ydata, newFile, ShowNotification, message, errorMessage, toggleDrawer) =>
  async (dispatch) => {
    const formdata = new FormData();

    formdata.append('ydata', JSON.stringify(ydata));
    formdata.append('foto', newFile);

    console.log([...formdata]);
    await Instance()
      .post('/gestao/utilizadores', formdata)
      .then(({ data }) => {
        // console.log(data);
        dispatch({ type: 'POST_UTILIZADOR', payload: data });
        toggleDrawer('right', false);
        dispatch(ShowNotification(message));
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 403) {
            const warningMessage = {
              isOpen: true,
              message: err.response.data.message,
              type: 'warning',
            };

            dispatch(ShowNotification(warningMessage));
          } else {
            dispatch(ShowNotification(errorMessage)).then(() => {
              toggleDrawer('right', false);
            });
          }
        }
      });
  };

export default AdicionarUtilizador;
