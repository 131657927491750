import React, { useState } from 'react';
import { Button, Typography } from '@mui/material';
import FeatherIcon from 'feather-icons-react';

const FileUploaderFirstBackground = ({
  classes,
  empresa,
  setEmpresa,
  // FILE UPLOAD STATES
  firstBackgroundFile,
  setFirstBackgroundFile,
}) => {
  const [showImage, setShowImage] = useState(false);

  const handleFileChange = (e) => {
    console.log('firstBackgroundFile', firstBackgroundFile);
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      var reader = new FileReader();
      // eslint-disable-next-line no-unused-vars
      var url = reader.readAsDataURL(file);

      reader.onloadend = () => {
        setFirstBackgroundFile({
          imgSrc: [reader.result],
          file: file,
        });
      };
    }
    e.target.value = '';
  };

  let fileType = '';

  if (
    empresa &&
    empresa.fundo1 &&
    empresa.fundo1.split('/') &&
    empresa.fundo1.split('/').length === 5
  ) {
    fileType = empresa.fundo1.split('/')[4].split('.')[1];
  } else if (
    empresa &&
    empresa.fundo1 &&
    empresa.fundo1.split('/') &&
    empresa.fundo1.split('/').length === 6
  ) {
    fileType = empresa.fundo1.split('/')[5].split('.')[1];
  } else if (
    empresa &&
    empresa.fundo1 &&
    empresa.fundo1.split('/') &&
    empresa.fundo1.split('/').length === 7
  ) {
    fileType = empresa.fundo1.split('/')[6].split('.')[1];
  } else if (empresa && empresa.fundo1 == null) {
    fileType = ' ';
  }
  return (
    <>
      {/*------------------ LOGÓTIPO --------------------*/}
      <Typography fontSize={13}>Primeiro Background</Typography>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div>
          {firstBackgroundFile &&
          Object.values(firstBackgroundFile) &&
          Object.values(firstBackgroundFile).length > 0 ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                height: '33px',
              }}
            >
              <div
                style={{
                  marginLeft: '8px',
                  display: 'flex',
                }}
              >
                <FeatherIcon
                  icon='x-square'
                  size={20}
                  style={{
                    strokeWidth: '1.5',
                    marginRight: '15px',
                    cursor: 'pointer',
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    setFirstBackgroundFile({});
                    setShowImage(false);
                  }}
                />

                <p style={{ margin: 0, fontSize: '14px' }}>
                  {firstBackgroundFile &&
                  firstBackgroundFile.file &&
                  firstBackgroundFile.file.name &&
                  Object.values(firstBackgroundFile.file.name).length > 25
                    ? `${
                        firstBackgroundFile &&
                        firstBackgroundFile.file &&
                        firstBackgroundFile.file.name &&
                        firstBackgroundFile.file.name.substring(0, 25)
                      } ...`
                    : firstBackgroundFile &&
                      firstBackgroundFile.file &&
                      firstBackgroundFile.file.name}
                  {empresa &&
                  empresa.foto &&
                  firstBackgroundFile &&
                  !firstBackgroundFile.file
                    ? `${empresa.nome}_fundo1.${fileType}`
                    : null}
                </p>
              </div>
              <div
                style={{
                  height: '20px',
                }}
              >
                <FeatherIcon
                  icon={showImage ? 'eye' : 'eye-off'}
                  size={20}
                  style={{
                    strokeWidth: '1.5',
                    cursor: 'pointer',
                  }}
                  onClick={() => setShowImage(!showImage)}
                />
              </div>
            </div>
          ) : (
            <>
              {empresa && empresa.fundo1 && empresa.fundo1.length > 0 ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                    height: '33px',
                  }}
                >
                  <div
                    style={{
                      marginLeft: '8px',
                      display: 'flex',
                    }}
                  >
                    <FeatherIcon
                      icon='x-square'
                      size={20}
                      style={{
                        strokeWidth: '1.5',
                        marginRight: '15px',
                        cursor: 'pointer',
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        setFirstBackgroundFile({});
                        setEmpresa({ ...empresa, fundo1: '' });
                        setShowImage(false);
                      }}
                    />

                    <p style={{ margin: 0, fontSize: '14px' }}>
                      {empresa &&
                      empresa.fundo1 &&
                      firstBackgroundFile &&
                      !firstBackgroundFile.file
                        ? `${empresa.nome}_fundo1.${fileType}`
                        : null}
                    </p>
                  </div>
                  <div
                    style={{
                      height: '20px',
                    }}
                  >
                    <FeatherIcon
                      icon={showImage ? 'eye' : 'eye-off'}
                      size={20}
                      style={{
                        strokeWidth: '1.5',
                        cursor: 'pointer',
                      }}
                      onClick={() => setShowImage(!showImage)}
                    />
                  </div>
                </div>
              ) : (
                <Button className={classes.btnCarregarImagem}>
                  <label
                    htmlFor='firstBackground'
                    className='custom-file-upload'
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'start',
                        cursor: 'pointer',
                      }}
                    >
                      <FeatherIcon
                        icon='image'
                        size={20}
                        style={{ strokeWidth: '1.5', marginRight: '5px' }}
                      />
                      <p style={{ margin: '0px 10px', fontSize: '12px' }}>
                        Carregar Imagem
                      </p>
                    </div>
                  </label>
                </Button>
              )}
            </>
          )}

          <input
            type='file'
            name='firstBackground'
            id='firstBackground'
            hidden
            onChange={(e) => handleFileChange(e)}
            multiple
            // accept='.jpg'
          />
        </div>

        <div className='profileImage' style={{ margin: 'auto' }}>
          {firstBackgroundFile && firstBackgroundFile.imgSrc && showImage ? (
            <img
              src={firstBackgroundFile.imgSrc}
              alt=''
              style={{ width: '200px', borderRadius: '10px' }}
            />
          ) : (
            <>
              {showImage && (
                <img
                  src={empresa.fundo1}
                  alt=''
                  style={{ width: '200px', borderRadius: '10px' }}
                />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default FileUploaderFirstBackground;
