import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import {
  TextField,
  Autocomplete,
  IconButton,
  Collapse,
  Card,
  CardActions,
  CardContent,
  Button,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';

const EmpresaCard = ({
  index,
  errors,
  empresa,
  perfis,
  control,
  classes,
  handleChangePerfisId,
  // handleRemoverEmpresa,
  utilizador,
  setOpenedCards,
  getValues,
  setResetForm,
  removerEmpresas,
  setRemoverEmpresas,
  setUtilizador,
  reset,
  watch,
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const handleExpandEmpresa = () => {
    setOpen(!open);
    setOpenedCards(!open);
  };

  let empresasDropdown = useSelector(
    (state) => state.utilizadores.empresasDropdown
  );

  // Retira as empresas que já estão preenchidas do dropdown de empresas
  useEffect(() => {
    const preEmpresas = watch('empresas');
    preEmpresas.map(async (empresa) => {
      for (let i = 0; i < preEmpresas?.length; i++) {
        //Finds the index value of the selected objet in the array
        const objIndexId = empresasDropdown?.findIndex(
          (obj) => obj.id === empresa?.empresasDropdown?.id
        );
        if (objIndexId > -1) {
          // Removes the object from the array
          empresasDropdown.splice(objIndexId, 1);
          await dispatch({
            type: 'GET_UTILIZADOR_EMPRESAS_DROPDOWN',
            payload: empresasDropdown,
          });
        }
      }
    });
  }, []);

  const handleRemoverEmpresa = async (empresa) => {
    const utilizador = getValues();
    const empresasCards = watch('empresas');

    const indexEmpresaSelected = empresasCards?.[empresa]?.empresasDropdown;

    console.log(empresasCards);
    console.log(indexEmpresaSelected);

    await setResetForm(true);

    if (index !== -1) {
      utilizador.empresas.splice(index, 1);
    }

    await setRemoverEmpresas([...removerEmpresas, empresa && empresa.id]);
    await setUtilizador({ ...utilizador });
    await setResetForm(false);
    await reset({ ...utilizador });

    //Adds the value to the array
    const newArray = empresasDropdown.concat(indexEmpresaSelected);
    //Equals the *empresasDropdown* array to the newarray
    empresasDropdown = newArray;
    // Sorts the array by id (ascendent)
    empresasDropdown.sort((a, b) => a.id - b.id);

    await dispatch({
      type: 'GET_UTILIZADOR_EMPRESAS_DROPDOWN',
      payload: empresasDropdown,
    });
  };

  const handleRemoverEmpresaDropdown = async (selectValue, removedValue) => {
    if (selectValue != null) {
      //Finds the index value of the selected objet in the array
      const objIndexId = empresasDropdown.findIndex(
        (obj) => obj.id === selectValue.id
      );
      // Removes the object from the array
      empresasDropdown.splice(objIndexId, 1);

      await dispatch({
        type: 'GET_UTILIZADOR_EMPRESAS_DROPDOWN',
        payload: empresasDropdown,
      });
    } else if (removedValue != null) {
      //Adds the value to the array
      const newArray = empresasDropdown.concat(removedValue);
      //Equals the *empresasDropdown* array to the newarray
      empresasDropdown = newArray;
      // Sorts the array by id (ascendent)
      empresasDropdown.sort((a, b) => a.id - b.id);

      await dispatch({
        type: 'GET_UTILIZADOR_EMPRESAS_DROPDOWN',
        payload: empresasDropdown,
      });

      console.log(empresasDropdown);
    }
  };

  return (
    <>
      <Card
        className={classes.root}
        style={{
          width: '100%',
          background: index % 2 ? '' : '#fafafa',
          marginBottom: '10px',
        }}
      >
        <CardActions
          disableSpacing
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ display: 'flex', width: '100%' }}>
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: open,
              })}
              onClick={handleExpandEmpresa}
              aria-expanded={open}
              aria-label='show more'
              style={{ width: '40px', height: '40px', marginTop: '10px' }}
            >
              <ExpandMoreIcon />
            </IconButton>
            <Controller
              name={`empresas[${index}][empresasDropdown]`}
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  <Autocomplete
                    clearText='Limpar'
                    openText='Abrir'
                    closeText='Fechar'
                    noOptionsText='Sem dados'
                    options={empresasDropdown || []}
                    value={value || null}
                    getOptionLabel={(option) => `${option.nome}` || ''}
                    isOptionEqualToValue={(option, value) => {
                      // return value.id === option.id;
                      return value.id > 0;
                    }}
                    style={{ marginTop: '10px', width: '39%' }}
                    filterOptions={(options, state) => {
                      let newOptions = [];
                      options.forEach((option) => {
                        if (
                          option.id > 0 &&
                          option.nome
                            .toLowerCase()
                            .includes(state.inputValue.toLowerCase())
                        )
                          newOptions.push(option);
                      });
                      return newOptions;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size='small'
                        label='Empresa'
                        placeholder='Empresa'
                        variant='outlined'
                        error={
                          !!errors.empresas &&
                          !!errors.empresas[index] &&
                          !!errors.empresas[index].empresasDropdown
                        }
                        helperText={
                          errors.empresas &&
                          errors.empresas[index] &&
                          errors.empresas[index].empresasDropdown
                            ? errors.empresas[index].empresasDropdown?.message
                            : ''
                        }
                      />
                    )}
                    onChange={(_, valueChange) => {
                      onChange(valueChange);
                      handleRemoverEmpresaDropdown(valueChange, value);
                      // handleChangePerfisId(value);
                      return value;
                    }}
                  />
                </>
              )}
            />
          </div>
          {/* <h4 onClick={handleRemoveSub}>X</h4> */}
          <div style={{ display: 'flex' }}>
            <a
              href={empresa && empresa.cplink}
              target='_blank'
              rel='noreferrer'
            >
              <Button
                className={classes.btnEmpresaWebsite}
                style={{ marginRight: '8px' }}
              >
                <p style={{ fontWeight: 'normal' }}>Visitar</p>
                <FeatherIcon
                  icon='link'
                  size={18}
                  style={{ strokeWidth: '2', marginLeft: '10px' }}
                />
              </Button>
            </a>
            <Button
              onClick={() => handleRemoverEmpresa(index, empresa)}
              className={classes.btnRemoverEmpresa}
              style={{ marginRight: '8px' }}
            >
              <FeatherIcon
                icon='trash-2'
                size={20}
                style={{ strokeWidth: '1.5' }}
              />
            </Button>
          </div>
        </CardActions>
        <Collapse in={open} timeout='auto' unmountOnExit>
          <CardContent>
            <div style={{ marginLeft: '10px', marginRight: '10px' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                }}
              >
                <Controller
                  name={`empresas[${index}][email]`}
                  control={control}
                  render={({ field }) => (
                    <>
                      <TextField
                        label='Email'
                        color='primary'
                        variant='outlined'
                        size='small'
                        {...field}
                        fullWidth
                        style={{
                          marginTop: '10px',
                          width: '59%',
                        }}
                        // error={!!errors.email}
                        // helperText={
                        //   errors.email ? errors.email?.message : ''
                        // }
                      />
                    </>
                  )}
                />

                <span style={{ width: '2%' }} />
                <Controller
                  name={`empresas[${index}][contato]`}
                  control={control}
                  render={({ field }) => (
                    <>
                      <TextField
                        label='Contato'
                        color='primary'
                        variant='outlined'
                        size='small'
                        {...field}
                        fullWidth
                        style={{
                          marginTop: '10px',
                          width: '39%',
                        }}
                        // error={!!errors.contato}
                        // helperText={
                        //   errors.email ? errors.email?.message : ''
                        // }
                      />
                    </>
                  )}
                />
              </div>
            </div>
            <div style={{ marginLeft: '10px', marginRight: '10px' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                }}
              >
                <Controller
                  name={`empresas[${index}][cargo]`}
                  control={control}
                  render={({ field }) => (
                    <>
                      <TextField
                        label='Cargo'
                        color='primary'
                        variant='outlined'
                        size='small'
                        {...field}
                        fullWidth
                        style={{
                          marginTop: '10px',
                          width: '59%',
                        }}
                        // error={!!errors.email}
                        // helperText={
                        //   errors.email ? errors.email?.message : ''
                        // }
                      />
                    </>
                  )}
                />

                <span style={{ width: '2%' }} />
                <Controller
                  name={`empresas[${index}][perfilDropdown]`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <Autocomplete
                        clearText='Limpar'
                        openText='Abrir'
                        closeText='Fechar'
                        noOptionsText='Sem dados'
                        options={perfis || []}
                        value={value || null}
                        getOptionLabel={(option) => `${option.descricao}` || ''}
                        isOptionEqualToValue={(option, value) => {
                          return value.id === option.id;
                        }}
                        style={{
                          marginTop: '10px',
                          width: '39%',
                        }}
                        filterOptions={(options, state) => {
                          let newOptions = [];
                          options.forEach((option) => {
                            if (
                              option.id > 0 &&
                              option.descricao
                                .toLowerCase()
                                .includes(state.inputValue.toLowerCase())
                            )
                              newOptions.push(option);
                          });
                          return newOptions;
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size='small'
                            label='Perfil'
                            placeholder='Perfil'
                            variant='outlined'
                            error={
                              !!errors.empresas &&
                              !!errors.empresas[index] &&
                              !!errors.empresas[index].perfilDropdown
                            }
                            helperText={
                              errors.empresas &&
                              errors.empresas[index] &&
                              errors.empresas[index].perfilDropdown
                                ? errors.empresas[index].perfilDropdown?.message
                                : ''
                            }
                          />
                        )}
                        onChange={(_, value) => {
                          onChange(value);
                          // handleChangePerfisId(value);
                          return value;
                        }}
                      />
                    </>
                  )}
                />
              </div>
            </div>
          </CardContent>
        </Collapse>
      </Card>
    </>
  );
};

export default EmpresaCard;
