import axios from 'axios';
import BASE_URL from '../../../BASE_URL';

const RecuperarSenha =
  (
    ydata,
    navigate,
    counter,
    setCounter,
    ShowNotification,
    message,
    errorMessage
  ) =>
  async (dispatch) => {
    await axios
      .post(`${BASE_URL}/gestao/utilizadores/recuperarsenha`, { ydata })
      .then(({ data }) => {
        console.log(data);

        setCounter({
          ...counter,
          running: true,
        });
      })
      .then(() => ShowNotification('success', message))
      .catch(({ err }) => {
        ShowNotification('alert', errorMessage);
        console.log(err);
      });
  };
export default RecuperarSenha;
