/* eslint-disable eqeqeq */
import './App.css';
import './Global/css/modals.css';
import { useSelector } from 'react-redux';
// import { ThemeProvider } from '@mui/material/styles';
// import theme from './Global/css/MuiTheme/theme';

//Router
import { Routes, Route, useLocation } from 'react-router-dom';

//Components
import TopAppbar from './Global/TopAppbar';
import Login from './pages/Global/Login/login';
import Error404 from './pages/Global/Error/error';
import DefinirSenha from './pages/Global/definirSenha/definirSenha';
import RecuperarSenha from './pages/Global/recuperarSenha/recuperarSenha';
import ProtectedRoute from './Router/ProtectedRoute';
// import PersistentDrawerLeft from './Global/Components/SmallMenu/index';
import NotificationSnackbar from './Global/Components/Notification/Notification';
import Sidebar from './Global/Sidebar/sidebarDashboard';

// ---- UTILIZADORES ----- //
import Utilizadores from './pages/App/Utilizadores';

// ---- UTILIZADORES ----- //
import Empresas from './pages/App/Empresas';

//ACTIONS

const App = () => {
  const location = useLocation();

  const user = useSelector((state) => state.user.user);
  // console.log(user);

  const token = localStorage.token;

  const page404 = location.pathname === '/404';

  return (
    <div className='App'>
      {!page404 == true ? (
        <>
          {/* {token ? <Drawer /> : null} */}
          {token ? <TopAppbar /> : null}
          {/* {token && <PersistentDrawerLeft />} */}
        </>
      ) : null}

      <Routes>
        <Route exact path='/' element={<Login />} />

        {/* OPENED ROUTES */}
        <Route path='/definirsenha/:hash' element={<DefinirSenha />} />
        <Route path='/recuperarSenha' element={<RecuperarSenha />} />
        {/* Protected Routes */}
        {/* <Route element={<ProtectedRoute isAllowed={!!user} />} /> */}
        <Route element={<ProtectedRoute isAllowed={user} />} />

        {/* PROTECTED ROUTES WITH PERMISSIONS */}
        {/* <Drawer> */}

        <Route
          path='/utilizadores'
          element={
            <ProtectedRoute
              redirectPath='/'
              // isAllowed={true}
              isAllowed={user && user.acesso}
            >
              <div style={{ display: 'flex' }}>
                <Sidebar />
                <Utilizadores />
              </div>

              {/* <Utilizadores /> */}
            </ProtectedRoute>
          }
        />
        <Route
          path='/empresas'
          element={
            <ProtectedRoute
              redirectPath='/'
              isAllowed={true}
              // isAllowed={user && user.master}
            >
              <div style={{ display: 'flex' }}>
                <Sidebar />
                <Empresas />
              </div>
            </ProtectedRoute>
          }
        />

        <Route path='*' element={<Error404 />} />
      </Routes>

      <NotificationSnackbar />
    </div>
  );
};

export default App;
