import { useSelector } from 'react-redux';
import { Snackbar, Alert } from '@mui/material';

const ErrorSnackBar = ({ handleClose }) => {
  const snack = useSelector((state) => state.notification.error);
  return (
    <>
      <Snackbar
        open={snack.isOpen}
        onClose={handleClose}
        autoHideDuration={3500}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        sx={{ mt: 7 }}
      >
        <Alert variant='filled' severity={snack.type} onClose={handleClose}>
          {snack.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ErrorSnackBar;
