/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
//PACKAGES
import { TextField, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { makeStyles } from '@mui/styles';

//ACTIONS

import FormRecuperarSenha from './components/formRecuperarSenha';
import FooterLinks from '../../../Global/Components/Footer';

const CssTextField = styled(TextField)({
  borderRadius: '10px',
  '& label.Mui-focused': {
    // color: '#464255 !important',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#464255 !important',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '10px',
    transition: '0.2s',
    // background: '#F3F2F7',
    '& input': {
      transition: '0.2s',
      borderRadius: '10px',
      background: '#F3F2F7',
    },
    '& fieldset': {
      // color: '#474a52 !important',
      transition: '0.2s',
    },
    '&:hover fieldset': {
      borderColor: '#F3F2F7 !important',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#F3F2F7 !important',
    },
  },
});

const useStyles = makeStyles({
  btnGuardar: {
    color: 'white',
    width: '120px',
    height: '40px',
    // borderRadius: '15px',
    backgroundColor: '#A28DF4',
    border: `1px solid #A28DF4`,
    display: 'flex',
    alignItems: 'center',
    transition: '0.2s',
    '&:hover': {
      backgroundColor: '#856af0',
      border: '1px solid #856af0',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#bc1041',
      },
      '&:disabled': {
        backgroundColor: '#bc1041',
      },
    },
  },
});

const RecuperarSenha = () => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const hash = location.pathname.split('/')[2];

  const storage = localStorage;

  return (
    <>
      {storage.token ? (
        navigate('/')
      ) : (
        <div className='home'>
          <div className='home-left'>
            <div className='left-content'>
              <div className='left-content-inside'></div>
            </div>
          </div>
          <div className='home-right'>
            <div className='login-form'>
              <div className='titulo'>
                {/* <Typography color=''>Bem Vindo!</Typography> */}
                <h3>Recuperar senha</h3>
                <Typography variant='h5' style={{ marginTop: 20 }}>
                  <strong>Gestão de Cartões Pessoais</strong>
                </Typography>
              </div>

              <div style={{ width: '100%' }}>
                <FormRecuperarSenha
                  onSubmit
                  form='log_in_form'
                  dispatch={dispatch}
                  hash={hash}
                  CssTextField={CssTextField}
                  classes={classes}
                />
              </div>
            </div>
            <div className='bottom'>
              <div style={{ padding: '20px 40px' }}>
                {/* <FooterLinks /> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RecuperarSenha;
