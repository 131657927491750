import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
// import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Drawer } from '@mui/material';
//Components
import FormEdit from './forms/formEdit';
import FormAdd from './forms/formAdd';

const useStyles = makeStyles({
  list: {
    width: '50vw',
    borderRadius: '10px 0px 0px 10px !important',
  },
  fullList: {
    width: 'auto',
  },
  paper: {
    background: '#FAFAFA',
    borderRadius: ' 12px 0px 0px 12px',
  },
  btnGuardar: {
    color: 'white',
    width: '120px',
    height: '40px',
    // borderRadius: '15px',
    backgroundColor: '#A28DF4',
    border: `1px solid #A28DF4`,
    display: 'flex',
    alignItems: 'center',
    transition: '0.2s',
    '&:hover': {
      backgroundColor: '#856af0',
      border: '1px solid #856af0',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#bc1041',
      },
      '&:disabled': {
        backgroundColor: '#bc1041',
      },
    },
  },
  btnCarregarImagem: {
    color: 'white',
    height: '30px',
    // borderRadius: '15px',
    backgroundColor: '#A28DF4',
    border: `1px solid #A28DF4`,
    display: 'flex',
    alignItems: 'center',
    padding: '12px 12px',
    transition: '0.2s',
    '&:hover': {
      backgroundColor: '#856af0',
      border: '1px solid #856af0',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#bc1041',
      },
      '&:disabled': {
        backgroundColor: '#bc1041',
      },
    },
  },
  btnCloseDrawer: {
    color: '#464255',
    width: '30px',
    height: '40px',
    borderRadius: '15px',
    backgroundColor: '#f2f2f2',
    border: `1px solid #f2f2f2`,
    display: 'flex',
    alignItems: 'center',
    transition: '0.2s',
    '&:hover': {
      backgroundColor: '#e0e0e0',
      border: '1px solid #e0e0e0',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#e0e0e0',
      },
    },
  },
  drawerHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  tab: {
    fontWeight: 'bold',
    textTransform: 'capitalize',
  },
  badge: {
    // fontSize: '20px',
    padding: '12px 8px',
    borderRadius: '50%',
    cursor: 'pointer',
    transition: '0.2s ease',
    border: 'solid 1px #ccc',
    backgroundColor: '#ccc',
    '&:hover': {
      backgroundColor: '#b8b8b8',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#ccc',
      },
    },
  },
});

const schema = yup.object().shape({
  nome: yup.string().required('Preenchimento obrigatório'),
});

const SideDrawer = ({
  state,
  toggleDrawer,
  empresas,
  fetchEmpresas,
  drawerType,
  empresa,
  setEmpresa,
  empresaId,
  empresaInfo,
}) => {
  const classes = useStyles();

  const [guardarLoading, setGuardarLoading] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    getValues,
    watch,
    setValue,
    trigger,
  } = useForm({
    defaultValues: {
      nome: '',
      url: '',
      morada: '',
      corFundo: '',
      corBotoes: '',
      corLetras: '',
      fundo1: {},
      fundo2: {},
      icon1: {},
      icon2: {},
    },
    resolver: yupResolver(schema),
  });

  return (
    <div>
      <Drawer
        anchor={'right'}
        open={state['right']}
        onClose={() => toggleDrawer('right', false)}
        classes={{ paper: classes.paper }}
      >
        <div className={classes.list}>
          {drawerType === 'add' && (
            <FormAdd
              toggleDrawer={toggleDrawer}
              fetchEmpresas={fetchEmpresas}
              empresa={empresa}
              setEmpresa={setEmpresa}
              empresaInfo={empresaInfo}
              classes={classes}
              guardarLoading={guardarLoading}
              setGuardarLoading={setGuardarLoading}
              // React hook form
              control={control}
              handleSubmitForm={handleSubmit}
              errors={errors}
              reset={reset}
              getValues={getValues}
              watch={watch}
              setValue={setValue}
              trigger={trigger}
            />
          )}
          {drawerType === 'edit' && (
            <FormEdit
              toggleDrawer={toggleDrawer}
              empresas={empresas}
              fetchEmpresas={fetchEmpresas}
              empresa={empresa}
              setEmpresa={setEmpresa}
              empresaId={empresaId}
              empresaInfo={empresaInfo}
              classes={classes}
              guardarLoading={guardarLoading}
              setGuardarLoading={setGuardarLoading}
              // React hook form
              control={control}
              handleSubmitForm={handleSubmit}
              errors={errors}
              reset={reset}
              getValues={getValues}
              watch={watch}
              setValue={setValue}
              trigger={trigger}
            />
          )}
        </div>
      </Drawer>
    </div>
  );
};

export default SideDrawer;
