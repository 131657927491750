/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Controller } from 'react-hook-form';
import { Button, TextField, Paper } from '@mui/material';

import LoadingButton from '@mui/lab/LoadingButton';

//Icons
import SaveIcon from '@mui/icons-material/Save';
import FeatherIcon from 'feather-icons-react';

//Actions
import ShowNotification from '../../../../../utils/redux/actions/global/notifications/NotificationAction';
import EditarEmpresa from '../../../../../utils/redux/actions/empresas/EditarEmpresaAction';

//Components
import Spinner from '../../../../../Global/Components/Spinner';
import EmpresaColorPickers from './components/colorPickers';
import FileUploaderImages from './components/fileUploaderImage';
import FileUploaderFirstLogo from './components/fileUploaderFirstLogo';
import FileUploaderSecondLogo from './components/fileUploaderSecondLogo';
import FileUploaderFirstBackground from './components/fileUploaderFirstBackground';
import FileUploaderSecondBackground from './components/fileUploaderSecondBackground';

const FormEdit = ({
  toggleDrawer,
  fetchEmpresas,
  empresa,
  setEmpresa,
  empresaId,
  classes,
  guardarLoading,
  setGuardarLoading,
  // React hook form
  control,
  handleSubmitForm,
  errors,
  reset,
  getValues,
  watch,
  setValue,
  trigger,
}) => {
  const dispatch = useDispatch();

  const [resetForm, setResetForm] = useState(false);
  console.log(empresaId);

  // FILE UPLOAD
  const [logoFile, setLogoFile] = useState([]);
  const [firstIconFile, setFirstIconFile] = useState([]);
  const [secondIconFile, setSecondIconFile] = useState([]);
  const [firstBackgroundFile, setFirstBackgroundFile] = useState([]);
  const [secondBackgroundFile, setSecondBackgroundFile] = useState([]);
  const [removeFiles, setRemoveFiles] = useState([]);

  const empresaInfo = useSelector((state) => state.empresas.empresa);

  useEffect(() => {
    if (empresaInfo) {
      setEmpresa(empresaInfo);
      reset(empresaInfo);
    }
  }, [empresaInfo]);

  const onSubmit = (data) => {
    console.log(data);
    console.log(empresa);

    const ydata = [
      {
        nome: data.nome,
        url: data.url,
        morada: data.morada,
        corFundo:
          data.corFundo === empresa.corFundo
            ? data.corFundo
            : data.corFundo.css.backgroundColor,
        corBotoes:
          data.corBotoes === empresa.corBotoes
            ? data.corBotoes
            : data.corBotoes.css.backgroundColor,
        corLetras:
          data.corLetras === empresa.corLetras
            ? data.corLetras
            : data.corLetras.css.backgroundColor,
        fundo1: empresa.fundo1,
        fundo2: empresa.fundo2,
        icon1: empresa.icon1,
        icon2: empresa.icon2,
        logotipo: empresa.logotipo,
      },
    ];

    console.log(ydata[0]);

    const message = {
      isOpen: true,
      message: 'Empresa editada com sucesso!!',
      type: 'success',
    };
    const errorMessage = {
      isOpen: true,
      message: 'Ocorreu um erro!!',
      type: 'error',
    };

    setGuardarLoading(true);

    dispatch(
      EditarEmpresa(
        ydata,
        empresaId,
        logoFile && logoFile.file,
        firstIconFile && firstIconFile.file,
        secondIconFile && secondIconFile.file,
        firstBackgroundFile && firstBackgroundFile.file,
        secondBackgroundFile && secondBackgroundFile.file,
        removeFiles,
        ShowNotification,
        message,
        errorMessage,
        toggleDrawer
      )
    )
      .then(() => fetchEmpresas())
      .then(() => setGuardarLoading(false));
  };

  return (
    <>
      {empresa ? (
        <div
          style={{
            isplay: 'flex',
            flexDirection: 'column',
            padding: '40px',
            height: '100%',
          }}
        >
          <form onSubmit={handleSubmitForm(onSubmit)}>
            <div className={classes.drawerHeader}>
              <div
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                }}
                onClick={() => toggleDrawer('right', false)}
              >
                <div>
                  <FeatherIcon
                    icon='chevron-left'
                    size={20}
                    style={{ strokeWidth: '1.5' }}
                  />
                </div>
                <div className='text-underline-close-drawer'>
                  Lista de Empresas
                </div>
              </div>

              {!guardarLoading ? (
                <Button className={classes.btnGuardar} type='submit'>
                  Guardar
                </Button>
              ) : (
                <LoadingButton
                  color='secondary'
                  loading={guardarLoading}
                  loadingPosition='start'
                  startIcon={<SaveIcon />}
                  variant='contained'
                  type='submit'
                  className={classes.btnGuardar}
                >
                  Guardar
                </LoadingButton>
              )}
            </div>
            <Paper sx={{ px: 6, py: 2, mt: 4 }}>
              <h4
                style={{
                  fontSize: '22px',
                  width: '100%',
                  marginBottom: '0px',
                }}
                className='text-underline'
              >
                Dados da empresa
              </h4>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                }}
              >
                <h4
                  style={{ color: '#E21450', width: '100%' }}
                  className='text-underline-title-drawer '
                >
                  Informações
                </h4>

                <Controller
                  name='nome'
                  control={control}
                  render={({ field }) => (
                    <>
                      <TextField
                        label='Nome'
                        color='primary'
                        variant='outlined'
                        size='small'
                        {...field}
                        fullWidth
                        style={{ marginTop: '10px', width: '49%' }}
                        error={!!errors.nome}
                        helperText={errors.nome ? errors.nome?.message : ''}
                      />
                    </>
                  )}
                />
                <span style={{ width: '2%' }} />
                <Controller
                  name='url'
                  control={control}
                  render={({ field }) => (
                    <>
                      <TextField
                        label='Site da empresa'
                        color='primary'
                        variant='outlined'
                        size='small'
                        {...field}
                        fullWidth
                        style={{ marginTop: '10px', width: '49%' }}
                        error={!!errors.contato}
                        helperText={
                          errors.contato ? errors.contato?.message : ''
                        }
                      />
                    </>
                  )}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                }}
              >
                <Controller
                  name='morada'
                  control={control}
                  render={({ field }) => (
                    <>
                      <TextField
                        label='Morada'
                        color='primary'
                        variant='outlined'
                        size='small'
                        {...field}
                        fullWidth
                        style={{ marginTop: '10px', width: '100%' }}
                        error={!!errors.morada}
                        helperText={errors.morada ? errors.morada?.message : ''}
                      />
                    </>
                  )}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                }}
              >
                <h4
                  style={{ color: '#E21450', width: '100%' }}
                  className='text-underline-title-drawer '
                >
                  Costumização
                </h4>
                <div style={{ width: '49%' }}>
                  <EmpresaColorPickers
                    control={control}
                    handleSubmitForm={handleSubmitForm}
                    errors={errors}
                    reset={reset}
                    getValues={getValues}
                    watch={watch}
                    setValue={setValue}
                    trigger={trigger}
                  />
                </div>
                <span style={{ width: '2%' }} />

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexWrap: 'wrap',
                    width: '49%',
                  }}
                >
                  <FileUploaderImages
                    classes={classes}
                    empresa={empresa}
                    setEmpresa={setEmpresa}
                    // FILE UPLOAD STATES
                    logoFile={logoFile}
                    setLogoFile={setLogoFile}
                  />
                  <FileUploaderFirstLogo
                    classes={classes}
                    empresa={empresa}
                    setEmpresa={setEmpresa}
                    // FILE UPLOAD STATES
                    firstIconFile={firstIconFile}
                    setFirstIconFile={setFirstIconFile}
                  />
                  <FileUploaderSecondLogo
                    classes={classes}
                    empresa={empresa}
                    setEmpresa={setEmpresa}
                    // FILE UPLOAD STATES
                    secondIconFile={secondIconFile}
                    setSecondIconFile={setSecondIconFile}
                  />
                  <FileUploaderFirstBackground
                    classes={classes}
                    empresa={empresa}
                    setEmpresa={setEmpresa}
                    // FILE UPLOAD STATES
                    firstBackgroundFile={firstBackgroundFile}
                    setFirstBackgroundFile={setFirstBackgroundFile}
                  />
                  <FileUploaderSecondBackground
                    classes={classes}
                    empresa={empresa}
                    setEmpresa={setEmpresa}
                    // FILE UPLOAD STATES
                    secondBackgroundFile={secondBackgroundFile}
                    setSecondBackgroundFile={setSecondBackgroundFile}
                  />
                </div>
              </div>

              {/* <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '30px',
                }}
              >
                {!guardarLoading ? (
                  <Button className={classes.btnGuardar} type='submit'>
                    Guardar
                  </Button>
                ) : (
                  <LoadingButton
                    color='secondary'
                    loading={guardarLoading}
                    loadingPosition='start'
                    startIcon={<SaveIcon />}
                    variant='contained'
                    type='submit'
                    className={classes.btnGuardar}
                  >
                    Guardar
                  </LoadingButton>
                )}
              </div> */}
            </Paper>
          </form>
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner />
        </div>
      )}
    </>
  );
};

export default FormEdit;
