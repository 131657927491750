import Instance from '../../../Axios';

// New Actions for the app

const GetEmpresasUtilizador = () => async (dispatch) => {
  await Instance()
    .get(`/gestao/utilizadores/empresasDropdown`)
    .then(({ data }) => {
      // console.log('empresas', data);
      dispatch({
        type: 'GET_UTILIZADOR_EMPRESAS_DROPDOWN',
        payload: data.yData,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetEmpresasUtilizador;
