import { utilizadoresInitialState } from '../initialState/utilizadores';

const utilizadoresReducer = (
  state = utilizadoresInitialState,
  { type, payload }
) => {
  switch (type) {
    case 'GET_UTILIZADORES':
      return {
        ...state,
        utilizadores: payload,
      };
    case 'GET_UTILIZADOR':
      return {
        ...state,
        utilizador: payload,
      };
    case 'POST_UTILIZADOR':
      return {
        ...state,
        novoUtilizador: payload,
      };
    case 'PUT_UTILIZADOR':
      return {
        ...state,
        utilizadorEditado: payload,
      };
    case 'DELETE_UTILIZADOR':
      return {
        ...state,
        utilizadorRemovido: payload,
      };

    case 'GET_PERFIS':
      return {
        ...state,
        perfis: payload,
      };
    case 'GET_UTILIZADOR_EMPRESAS_DROPDOWN':
      return {
        ...state,
        empresasDropdown: payload,
      };
    case 'GET_UTILIZADOR_EMPRESAS_DROPDOWN_BASE':
      return {
        ...state,
        empresasDropdownBase: payload,
      };

    case 'USER_LOGOUT':
      return {
        ...utilizadoresInitialState,
      };

    default:
      return state;
  }
};

export default utilizadoresReducer;
