import axios from 'axios';
import BASE_URL from '../../../BASE_URL';

const DadosSenhaUtilizador = (hash, setComponent) => async (dispatch) => {
  console.log(hash);
  await axios
    .get(`${BASE_URL}/gestao/signup/${hash}`)
    .then(({ data }) => {
      console.log(data);
      setComponent('form');
      dispatch({ type: 'GET_DEF_SENHA', payload: data.yData });
    })
    .catch((err) => {
      if (err.response) {
        if (err.response.status === 404) {
          setComponent('404');
          // dispatch(ShowNotification(warningMessage));
        } else {
          // dispatch(ShowNotification(errorMessage));
        }
      }
    });
};

export default DadosSenhaUtilizador;
