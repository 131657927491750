import { useDispatch } from 'react-redux';

//ACTIONS
import ClearNotification from '../../../utils/redux/actions/global/notifications/ClearNotificationAction';

//COMPONENTS
import SuccessSnackBar from './type/sucess';
import WarningSnackBar from './type/warning';
import ErrorSnackBar from './type/error';
import InfoSnackBar from './type/info';

export default function NotificationSnackbar() {
  const dispatch = useDispatch();

  function handleClose(event, reason) {
    if (reason === 'clickway') {
      return;
    }
    dispatch(ClearNotification());
  }

  return (
    <>
      <SuccessSnackBar handleClose={handleClose} />
      <WarningSnackBar handleClose={handleClose} />
      <ErrorSnackBar handleClose={handleClose} />
      <InfoSnackBar handleClose={handleClose} />
    </>
  );
}
