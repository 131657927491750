import Instance from '../../../Axios';

const EditarEmpresa =
  (
    ydata,
    empresaId,
    logoFile,
    firstIconFile,
    secondIconFile,
    firstBackgroundFile,
    secondBackgroundFile,
    removeFiles,
    ShowNotification,
    message,
    errorMessage,
    toggleDrawer
  ) =>
  async (dispatch) => {
    const formdata = new FormData();

    formdata.append('ydata', JSON.stringify(ydata));
    formdata.append('logotipo', logoFile);
    formdata.append('fundo1', firstBackgroundFile);
    formdata.append('fundo2', secondBackgroundFile);
    formdata.append('icon1', firstIconFile);
    formdata.append('icon2', secondIconFile);

    console.log([...formdata]);
    await Instance()
      .put(`/gestao/empresas/${empresaId}`, formdata)
      .then(({ data }) => {
        console.log(data);
        dispatch({ type: 'PUT_EMPRESA', payload: data });
        toggleDrawer('right', false);
        dispatch(ShowNotification(message));

        // toggleDrawer('right', false);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response === 403) {
            dispatch(ShowNotification(errorMessage));
          } else {
            dispatch(ShowNotification(errorMessage));
          }
        }
      });
  };

export default EditarEmpresa;
