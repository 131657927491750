import Instance from '../../../Axios';

// New Actions for the app

const GetPerfis = () => async (dispatch) => {
  await Instance()
    .get(`/gestao/perfis`)
    .then(({ data }) => {
      // console.log('perfis', data);
      dispatch({ type: 'GET_PERFIS', payload: data.yData });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetPerfis;
