/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';

//PACKAGES
import {
  Button,
  TextField,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
} from '@mui/material';
import styled from '@emotion/styled';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

//ACTIONS
import DefinirSenha from '../../../../utils/redux/actions/senha/definirSenhaAction';
import ShowNotification from '../../../../utils/redux/actions/global/notifications/NotificationAction';

const CssTextField = styled(TextField)({
  borderRadius: '10px',
  '& label.Mui-focused': {
    // color: '#464255 !important',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#464255 !important',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '10px',
    transition: '0.2s',
    // background: '#F3F2F7',
    '& input': {
      transition: '0.2s',
      borderRadius: '10px',
      background: '#F3F2F7',
    },
    '& fieldset': {
      // color: '#474a52 !important',
      transition: '0.2s',
    },
    '&:hover fieldset': {
      borderColor: '#F3F2F7 !important',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#F3F2F7 !important',
    },
  },
});

const FormDefinirSenha = ({
  dados,
  validateOnChange = true,
  hash,
  dispatch,
  classes,
}) => {
  const [user, setUser] = useState({
    password: '',
    confirmarPassword: '',
  });
  const [values, setValues] = useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });
  const [values_confirmar, setValues_confirmar] = useState({
    amount: '',
    confirmarpassword: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });

  const navigate = useNavigate();

  const handleChangePassword = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    setUser({ ...user, [prop]: event.target.value });

    if (validateOnChange)
      validationUser({ [event.target.name]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChangePasswordConfirmar = (prop) => (event) => {
    setValues_confirmar({ ...values_confirmar, [prop]: event.target.value });
    setUser({ ...user, [prop]: event.target.value });

    if (validateOnChange)
      validationUser({ [event.target.name]: event.target.value });
  };

  const handleClickShowPasswordConfirmar = () => {
    setValues_confirmar({
      ...values_confirmar,
      showPassword: !values_confirmar.showPassword,
    });
  };
  const handleMouseDownPasswordConfirmar = (event) => {
    event.preventDefault();
  };

  //--------------------------------- validation user--------------------------/
  const [errorsUser, setErrorsUser] = useState({});
  const validationUser = (fieldValues = user) => {
    const temp = { ...errorsUser };

    if ('password' in fieldValues)
      temp.password = fieldValues.password ? '' : 'Campo Obrigatório';
    if ('confirmarPassword' in fieldValues)
      temp.confirmarPassword = fieldValues.confirmarPassword
        ? ''
        : 'Campo Obrigatório';

    setErrorsUser({
      ...temp,
    });
    if (fieldValues == user) {
      return Object.values(temp).every((x) => x == '');
    }
    return false;
  };
  //--------------------------------------------------------------------------/

  const handleSubmit = (e) => {
    e.preventDefault();

    const acesso = dados.acesso;

    if (validationUser()) {
      const ydata = [
        {
          password: user.password,
          confirmarPassword: user.confirmarPassword,
          setup: hash,
        },
      ];

      const message = {
        isOpen: true,
        message: 'Senha definida com sucesso!!',
        type: 'success',
      };
      const errorMessage = {
        isOpen: true,
        message: 'Ocorreu um erro!!',
        type: 'error',
      };

      dispatch(
        DefinirSenha(
          ydata,
          acesso,
          navigate,
          ShowNotification,
          message,
          errorMessage
        )
      );
    }
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          paddingBottom: '40px',
        }}
      >
        <form className='loginForm' noValidate autoComplete='off'>
          <CssTextField
            id='email'
            label='Email'
            variant='outlined'
            className='emailInput'
            size='small'
            value={dados && dados.email}
            name='email'
            disabled
          />
          <FormControl
            onSubmit={handleSubmit}
            variant='outlined'
            {...(errorsUser.password && {
              error: true,
              helperText: errorsUser.password,
            })}
          >
            <CssTextField
              label='Password'
              id='outlined-start-adornment'
              value={values.password}
              type={values.showPassword ? 'text' : 'password'}
              name='password'
              onChange={handleChangePassword('password')}
              size='small'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge='end'
                    >
                      {values.showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              variant='outlined'
              {...(errorsUser.password && {
                error: true,
              })}
            />
            {errorsUser.password && (
              <FormHelperText style={{ color: 'red', borderColor: 'red' }}>
                {errorsUser.password}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl
            style={{ marginTop: '20px' }}
            onSubmit={handleSubmit}
            variant='outlined'
            {...(errorsUser.confirmarPassword && {
              error: true,
              helperText: errorsUser.confirmarPassword,
            })}
          >
            <CssTextField
              label='Confirmar Password'
              id='outlined-start-adornment'
              value={values_confirmar.password}
              type={values_confirmar.showPassword ? 'text' : 'password'}
              name='confirmarPassword'
              onChange={handleChangePasswordConfirmar('confirmarPassword')}
              size='small'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={handleClickShowPasswordConfirmar}
                      onMouseDown={handleMouseDownPasswordConfirmar}
                      edge='end'
                    >
                      {values_confirmar.showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              variant='outlined'
              {...(errorsUser.password && {
                error: true,
              })}
            />
            {errorsUser.confirmarPassword && (
              <FormHelperText style={{ color: 'red' }}>
                {errorsUser.confirmarPassword}
              </FormHelperText>
            )}
          </FormControl>
          <div className='login-submit-section'>
            <Button
              variant='contained'
              type='submit'
              onClick={handleSubmit}
              style={{ width: '100px' }}
              className={classes.btnGuardar}
            >
              Confirmar
            </Button>
            <Link to='/' style={{ color: 'black' }}>
              <p>Voltar para o Login</p>
            </Link>
          </div>
        </form>
      </div>
    </>
  );
};

export default FormDefinirSenha;
