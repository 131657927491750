import Instance from '../../../Axios';

const EditarUtilizador =
  (
    ydata,
    id,
    newFile,
    ShowNotification,
    message,
    errorMessage,
    toggleDrawer,
    setGuardarLoading
  ) =>
  async (dispatch) => {
    const formdata = new FormData();

    formdata.append('ydata', JSON.stringify(ydata));
    formdata.append('foto', newFile);

    console.log([...formdata]);
    await Instance()
      .put(`/gestao/utilizadores/${id}`, formdata)
      .then(({ data }) => {
        console.log(data);
        dispatch({ type: 'PUT_UTILIZADOR', payload: data });
        toggleDrawer('right', false);
        dispatch(ShowNotification(message));
        //   show('success', 'Foram associados novos funcionários á obra');
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 403) {
            const warningMessage = {
              isOpen: true,
              message: err.response.data.message,
              type: 'warning',
            };

            dispatch(ShowNotification(warningMessage));
          } else {
            dispatch(ShowNotification(errorMessage)).then(() => {
              toggleDrawer('right', false);
            });
          }
        }
      });
  };

export default EditarUtilizador;
