import React from 'react';
import './error.css';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
//PACKAGES

import { Button } from '@mui/material';

//COMPONENTS
// import Logo from '../../../images/logo_valleybeach_black.png';

const useStyles = makeStyles({
  btnGuardar: {
    color: 'white',
    width: '120px',
    height: '40px',
    // borderRadius: '15px',
    backgroundColor: '#A28DF4',
    border: `1px solid #A28DF4`,
    transition: '0.2s',
    '&:hover': {
      backgroundColor: '#856af0',
      border: '1px solid #856af0',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#bc1041',
      },
      '&:disabled': {
        backgroundColor: '#bc1041',
      },
    },
  },
});

const Error404 = () => {
  const classes = useStyles();
  const location = useLocation();
  const page404 = location.pathname === '/404';
  const navigate = useNavigate();

  return (
    // <MuiThemeProvider theme={theme}>
    <div className='home' style={{ height: '100vh' }}>
      {!page404 && <Navigate to={'/404'} replace />}
      {/* <Navigate to={'/404'} replace />; */}
      <div className='error-back'>
        {/* <img src={Logo} alt="" className="logo" /> */}
        <main
          style={{
            textAlign: 'center',
          }}
          className='mainContent'
        >
          <div>
            <div className='titulo-404'>
              <div>404</div>
            </div>

            <div>
              <p>
                A página que procura não foi encontrada! Por favor volte á
                página anterior.
              </p>
            </div>

            <Button
              variant='contained'
              color='primary'
              // className={classes.btnVoltar}
              className={classes.btnGuardar}
              onClick={() => navigate(-1)}
            >
              Voltar
            </Button>
          </div>
          <div className='bottom-404' style={{ height: '100%' }}>
            Para mais informações, contacte a administração.
          </div>
        </main>
      </div>
      {/* <div className='error-right'>
          <div className='center404'></div>
        </div> */}
    </div>
    // </MuiThemeProvider>
  );
};

export default Error404;
