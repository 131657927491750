/* eslint-disable array-callback-return */
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Tooltip,
} from '@mui/material';
import FeatherIcon from 'feather-icons-react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';

import { useState } from 'react';

const useStyles = makeStyles(() => ({
  button: {
    display: 'flex',
    justifyContent: 'center',
    height: 40,
    borderTop: '4px solid transparent',
    borderBottom: '4px solid transparent',

    '&:hover': {
      // borderRight: '4px solid #E21450',
      background: '#E0DAFB',
    },
  },
  divider: {
    borderLeft: '4px solid #E21450',
    borderRadius: '5px 0px 0px 5px',
    orientation: 'vertical',
    height: '30px',
    width: '5px',
  },
}));

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();

  const user = useSelector((state) => state.user.user);

  const menuList1a = [
    {
      id: 1,
      tooltip: 'Utilizadores',
      path: '/utilizadores',
      icon: 'users',
      permission: '',
      fiIconCheck: true,
    },
    {
      id: 2,
      tooltip: 'Empresas',
      path: '/empresas',
      icon: 'bar-chart-2',
      permission: '',
      fiIconCheck: true,
    },
  ];

  // eslint-disable-next-line no-unused-vars
  const [state, setState] = useState({
    settings: [
      { id: 2, open: false },
      { id: 3, open: false },
    ],
  });

  const handleClick = (id) => {
    setState((state) => ({
      ...state,
      settings: state.settings.map((item) =>
        item.id === id ? { ...item, open: !item.open } : item
      ),
    }));
  };

  return (
    <Box
      sx={{
        display: 'flex',
        zIndex: 1000,
      }}
    >
      <Box
        sx={{
          opacity: 1,
          boxShadow: '0px 5px 15px lightgrey',
          width: '70px',
          height: '100%',
          position: 'fixed',
          background: '#fff',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          mt: -6,
          pb: 2,
        }}
      >
        <Box>
          <Box sx={{ mt: 9 }}></Box>
          <List
            sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
          >
            {user && user.master === 1 ? (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  mt: 4,
                  justifyContent: 'space-between',
                  height: 'auto',
                }}
              >
                {menuList1a.map((item, index) => (
                  <div key={index}>
                    <ListItem
                      key={item.id}
                      disablePadding
                      onClick={() => navigate(item.path)}
                      sx={{ mb: 4 }}
                    >
                      <Tooltip title={item.tooltip} placement='right'>
                        <ListItemButton
                          sx={{
                            borderRight: location.pathname === item.path,
                            background:
                              location.pathname === item.path
                                ? '#E0DAFB'
                                : null,
                            marginRight: '10px',
                            marginLeft: '10px',
                            borderRadius: '8px',
                          }}
                          className={classes.button}
                          onClick={() =>
                            item && item.submenu && item.submenu.length > 0
                              ? handleClick(item.id)
                              : null
                          }
                        >
                          <ListItemIcon
                            sx={{ display: 'flex', justifyContent: 'center' }}
                          >
                            {item.fiIconCheck === true ? (
                              <FeatherIcon
                                icon={item.icon}
                                color='#464255'
                                size={30}
                                strokeWidth={1.5}
                              />
                            ) : (
                              item.icon
                            )}
                          </ListItemIcon>
                        </ListItemButton>
                      </Tooltip>
                    </ListItem>
                  </div>
                ))}
              </Box>
            ) : (
              <div>
                <ListItem
                  disablePadding
                  onClick={() => navigate('/utilizadores')}
                  sx={{ mb: 4, mt: 4 }}
                >
                  <Tooltip title='Utilizadores' placement='right'>
                    <ListItemButton
                      sx={{
                        borderRight: location.pathname === '/utilizadores',
                        background:
                          location.pathname === '/utilizadores'
                            ? '#E0DAFB'
                            : null,
                        marginRight: '10px',
                        marginLeft: '10px',
                        borderRadius: '8px',
                      }}
                      className={classes.button}
                    >
                      <ListItemIcon
                        sx={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <FeatherIcon
                          icon='users'
                          color='#464255'
                          size={30}
                          strokeWidth={1.5}
                        />
                      </ListItemIcon>
                    </ListItemButton>
                  </Tooltip>
                </ListItem>
              </div>
            )}

            <Divider
              variant='middle'
              sx={{
                mt: 2,
              }}
            />
          </List>
        </Box>
        <Box>
          <Divider
            variant='middle'
            sx={{
              mb: 2,
            }}
          />
          <List>
            <ListItem disablePadding>
              <Tooltip title='Sair' placement='right'>
                <ListItemButton
                  sx={{ display: 'flex', justifyContent: 'center' }}
                >
                  <ListItemIcon
                    sx={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <FeatherIcon
                      icon='log-out'
                      color='#707070'
                      size={30}
                      strokeWidth={1.5}
                    />
                  </ListItemIcon>
                </ListItemButton>
              </Tooltip>
            </ListItem>
          </List>
        </Box>
      </Box>
      <Box sx={{ pl: 8 }}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default Sidebar;
