import React from 'react';

import { Controller, useForm } from 'react-hook-form';
import { ColorPicker } from 'mui-color';
import { styled } from '@mui/styles';
import { Typography } from '@mui/material';

const EmpresaColorPickers = ({
  // React hook form
  control,
  handleSubmitForm,
  errors,
  reset,
  getValues,
  watch,
  setValue,
  trigger,
}) => {
  return (
    <form
      style={{ display: 'flex', justifyContent: 'space-evenly', width: '85%' }}
    >
      <div>
        <Typography fontSize={13}>QR Code</Typography>
        <Controller
          name='corFundo'
          control={control}
          // rules={{ validate: matchIsValidColor }}
          render={({ field, fieldState }) => (
            <>
              <ColorPicker
                {...field}
                name='corFundo'
                value={field.value}
                defaultValue={'#333'}
                hideTextfield
                // onClick={(value) => console.log(field)}
              />
            </>
          )}
        />
      </div>
      {/* <div>
        <Typography fontSize={13}>Cor dos Botões</Typography>
        <Controller
          name='corBotoes'
          control={control}
          // rules={{ validate: matchIsValidColor }}
          render={({ field, fieldState }) => (
            <>
              <ColorPicker
                {...field}
                name='corFundo'
                value={field.value}
                //   defaultValue={corObra}
                hideTextfield
                //   onChange={(value) => console.log(field)}
              />
            </>
          )}
        />
      </div> */}
      <div>
        <Typography fontSize={13}>Cor das Letras</Typography>
        <Controller
          name='corLetras'
          control={control}
          // rules={{ validate: matchIsValidColor }}
          render={({ field, fieldState }) => (
            <>
              <ColorPicker
                {...field}
                name='corFundo'
                value={field.value}
                //   defaultValue={corObra}
                hideTextfield
                //   onChange={(value) => console.log(field)}
              />
            </>
          )}
        />
      </div>
    </form>
  );
};

export default EmpresaColorPickers;
