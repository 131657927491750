import Instance from '../../../Axios';

const RemoverUtilizador =
  (id, ShowNotification, message, errorMessage, handleClose, e) =>
  async (dispatch) => {
    console.log(id);
    await Instance()
      .delete(`/gestao/utilizadores/${id}`)
      .then(({ data }) => {
        dispatch({ type: 'DELETE_UTILIZADOR', payload: data });
        dispatch(ShowNotification(message));
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 403) {
            const warningMessage = {
              isOpen: true,
              message: err.response.data.message,
              type: 'warning',
            };
            dispatch(ShowNotification(warningMessage)).then(() => {
              console.log('passei aqui');
              handleClose('delete', e);
            });
          } else {
            dispatch(ShowNotification(errorMessage)).then(() => {
              handleClose('delete', e);
            });
          }
        }
      });
  };

export default RemoverUtilizador;
